import React from 'react';
import { useTranslation } from 'react-i18next';
import Flagnp from '../images/flags/np.svg';
import Flagus from '../images/flags/us.svg';



const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='flagnp'>
      <button onClick={() => changeLanguage('ne')} className="flagButton">
        <img src={Flagnp} alt="Nepali" width="30" height="20" />
      </button>
      <button onClick={() => changeLanguage('en')} className="flagButton">
        <img src={Flagus} alt="English" width="30" height="20" />
      </button>
    </div>
  );
};

export default LanguageSelector;
