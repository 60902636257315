import React, { useEffect, useState, useContext } from 'react';
import { getGraphs, deleteGraph } from '../api/graphs';
import Graph from './Graph';
import GraphForm from './GraphForm';
import { UserContext } from '../../context/userContext';
import { FaEdit, FaTrash, FaPlus, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Modal from 'react-modal';
import '../../styles/Dashboard2.css'; // Assuming you create a separate CSS file for styling

Modal.setAppElement('#root');

const DashboardProfile = () => {
  const [graphs, setGraphs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editGraph, setEditGraph] = useState(null);
  const [expandedProfiles, setExpandedProfiles] = useState({});
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  const refreshGraphs = async () => {
    try {
      const response = await getGraphs();
      setGraphs(response.data);
    } catch (error) {
      console.error('Error fetching graphs', error);
    }
  };

  const handleDeleteGraph = async (id) => {
    try {
      await deleteGraph(id, token);
      refreshGraphs();
    } catch (error) {
      console.error('Error deleting graph', error);
    }
  };

  const handleEditGraph = (graph) => {
    setEditGraph(graph);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditGraph(null); // Reset editGraph state when closing the modal
  };

  const toggleProfile = (profileName) => {
    setExpandedProfiles(prev => ({
      ...prev,
      [profileName]: !prev[profileName]
    }));
  };

  useEffect(() => {
    refreshGraphs();
  }, []);

  const groupedGraphs = graphs.reduce((acc, graph) => {
    const profileName = graph.profile ? graph.profile.name : 'Uncategorized';
    (acc[profileName] = acc[profileName] || []).push(graph);
    return acc;
  }, {});

  return (
    <section className="dashboardProfile dashboard">
      <div className="containerProfile container">
        <button className="icon-button add-graph-button" onClick={() => setIsModalOpen(true)}>
          <FaPlus /> Add Graph
        </button>
        {Object.keys(groupedGraphs).map(profile => (
          <div key={profile} className="profile-section">
            <button className="accordion" onClick={() => toggleProfile(profile)}>
              {profile} {expandedProfiles[profile] ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {expandedProfiles[profile] && (
              <div className="profile-content">
                {groupedGraphs[profile].map(graph => (
                  <div key={graph._id} className="graph-item" >
                    <Graph graph={graph} />
                    <div className="graph-actions">
                      <button className="icon-button" onClick={() => handleEditGraph(graph)}>
                        <FaEdit />
                      </button>
                      <button className="icon-buttondel" onClick={() => handleDeleteGraph(graph._id)}>
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Graph Form"
        className="modal"
        overlayClassName="overlay"
      >
        <button className="close-button" onClick={handleCloseModal}>
          <FaTimes />
        </button>
        <GraphForm refreshGraphs={refreshGraphs} editGraph={editGraph} closeModal={handleCloseModal} />
      </Modal>
    </section>
  );
};

export default DashboardProfile;
