import React from 'react';
import { Box, Modal, Typography } from '@mui/material';

const PDFModal = ({ open, handleClose, url }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="pdf-modal-title"
            aria-describedby="pdf-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(10px)',
            }}
        >
            <Box sx={{ width: '80%', height: '80%', bgcolor: 'background.paper', p: 2 }}>
                <Typography id="pdf-modal-title" variant="h6" component="h2">
                    Preview
                </Typography>
                <iframe
                    src={url}
                    title="PDF Preview"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                ></iframe>
            </Box>
        </Modal>
    );
};

export default PDFModal;
