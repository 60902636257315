import React, { useState, useEffect, useContext } from 'react'
import PostItem from '../components/PostItem'
import Loader from '../components/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
// import { UserContext } from '../context/userContext'

const Complaints = () => {
  const { t } = useTranslation();


  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [subject, setSubject] = useState('')
  const [complaint, setComplaint] = useState('')
  const [images, setImages] = useState([])
  const [error, setError] = useState('')
  const navigate = useNavigate();

  // const { currentUser } = useContext(UserContext)
  // const token = currentUser?.token;

  //  redirect to login page for any user who isn't logged in
  // useEffect(() => {
  //   if (!token) {
  //     navigate('/login')
  //   }
  // },[])




  const createComplaint = async (e) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('to', to)
    postData.set('from', from)
    postData.set('subject', subject)
    postData.set('complaint', complaint)
    for (let i = 0; i < images.length; i++) {
      postData.append('images', images[i]);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/complaints`, postData)
      if (response.status == 201) {
        return navigate('/')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'images'") {
        setError("Please choose images.")
      } else {
        setError(err.response.data.message);
      }
    }
  }
  
  // to handle array of images
  const handleImageChange = (event) => {
    const files = event.target.files;
    // Convert the FileList object to an array
    const fileList = Array.from(files);
    // Update the images state with the array of selected files
    setImages(fileList);
  };


  return (
    <section className="posts">
      <div className="container contacts__container">
        <article className="post contacts">
          <h3 style={{ margin: "2rem", color: '#1fa194'}}>Fill Up Complaint Form:</h3>
          <div className="container-contact">
          <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={createComplaint}>
          <input type='text' placeholder={t('to')} value={to} onChange={e => setTo(e.target.value)} autoFocus className="complaint-textarea"/>
          <input type='text' placeholder={t('from')} value={from} onChange={e => setFrom(e.target.value)} autoFocus className="complaint-textarea" />
          <input type='text' placeholder={t('subject')} value={subject} onChange={e => setSubject(e.target.value)} autoFocus className="complaint-textarea"/>
          <textarea type='text' placeholder={t('entercomplaint')} value={complaint} onChange={e => setComplaint(e.target.value)} autoFocus className="expandable-textarea" />
          {/* <div class="container-quill"> */}
          <div className="thumbnaildash2">
              <span>Choose Images or Files : </span>
              <input type="file" onChange={handleImageChange} multiple/>
          </div>
            <div className="boxdash">
              <button type="submit" className='btn primary submitbtn2'>Send Complaint</button>
            </div>
          {/* </div> */}
            </form>
      </div>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Complaints
