import axios from 'axios';
import { getAuthHeaders } from '../../utility/useAuth';

// const API_URL = 'http://localhost:9000/api';
const API_URL = process.env.REACT_APP_BASE_URL

export const getGraphs = () => axios.get(`${API_URL}/graphs`);
export const createGraph = (data) => axios.post(`${API_URL}/graphs`, data, { headers: getAuthHeaders() });
export const updateGraph = (id, data) => axios.put(`${API_URL}/graphs/${id}`, data, { headers: getAuthHeaders() });
export const deleteGraph = (id) => axios.delete(`${API_URL}/graphs/${id}`, { headers: getAuthHeaders() });
export const getProfiles = () => axios.get(`${API_URL}/profiles`);
export const createProfile = (data) => axios.post(`${API_URL}/profiles`, data, { headers: getAuthHeaders() });
