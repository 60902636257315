import React, { useEffect, useState } from 'react';
import PostItem from './PostItem';
import { DUMMY_POSTS } from '../data';
import Loader from '../components/Loader';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedPosts, setDisplayedPosts] = useState(8); // Number of posts to display initially

  const { t } = useTranslation();


  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/businesses`);
        setPosts(response?.data);
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    fetchPosts();
  }, []);

  const handleViewMore = () => {
    // Increment the number of displayed posts by 12 when "View More" button is clicked
    setDisplayedPosts(displayedPosts + 8);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="posts">
      <h2 className="bannerHospitality">{t('exploreus')}</h2>
      {posts.length > 0 ? (
        <>
          <div className="container posts__container">
            {posts.slice(0, displayedPosts).map(({ _id: id, thumbnail, category, name, description, creator, createdAt }) => (
              <PostItem key={id} postID={id} thumbnail={thumbnail} category={category} title={name} description={description} authorID={creator} createdAt={createdAt} moduleName="businesses" />
            ))}
          </div>
          {posts.length > displayedPosts && ( // Render "View More" button only if there are more than 12 posts
            <div className="center">
              <button onClick={handleViewMore} className="view-more-button submitbtnHome">
                View More
              </button>
            </div>
          )}
        </>
      ) : (
        <h2 className="center">No Posts found</h2>
      )}
    </section>
  );
};

export default Posts;
