import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import axios from 'axios'
import '../../styles/dashboard.css';


const EditComplaint = () => {
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [complaint, setComplaint] = useState('')
  const [images, setImages] = useState([])
  const [error, setError] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState([]);


  const navigate = useNavigate();
  const { id } = useParams();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])

  // to fetch post data

  useEffect(() => {
    const getPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/complaints/${id}`)
        setTo(response.data.to)
        setFrom(response.data.from)
        setComplaint(response.data.complaint)
        setImages(response.data.images)
      } catch (error) {
        console.log(error)
      }
    }
    getPost();
  }, [id])


  // to edit post
  const editComplaint = async (e ) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('to', to)
    postData.set('from', from)
    postData.set('complaint', complaint)
    for (let i = 0; i < images.length; i++) {
        postData.append('images', images[i]);
    }
    console.log(postData.getAll("images"))
    try {
        const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/complaints/${id}`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
        console.log("response.data")
        console.log("Images:", postData.getAll('images'));
        if (response.status == 200) {
        return navigate('/myposts')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'images'") {
        setError("Please choose a images yo.")
      } else {
        setError(err.response.data.message);
      }
    }
  }

  //  to add more images
  const handleImageChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    const combinedFiles = [...images, ...fileList];
    setImages(combinedFiles);

    const urls = fileList.map((file) => URL.createObjectURL(file));
    setThumbnailUrl(urls);
  };
  
  // to remove images
  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedThumbnails = [...thumbnailUrl];
    updatedThumbnails.splice(index, 1);
    setThumbnailUrl(updatedThumbnails);
};
    

  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={editComplaint}>
            <input type='text' placeholder='To' value={to} onChange={e => setTo(e.target.value)} autoFocus />
            <input type='text' placeholder='From' value={from} onChange={e => setFrom(e.target.value)} autoFocus />
            <textarea type='text' placeholder='Complaint' value={complaint} onChange={e => setComplaint(e.target.value)} autoFocus className="expandable-textarea" />
          {/* <div class="container-quill"> */}
  {/* Display thumbnail image if selected */}
  <div className="thumbnail-container">
                        <input type="file" onChange={handleImageChange} accept="image/*" multiple className="custom-file-input"/>
                        {/* {thumbnailUrl.map((image, index) => (
                            <div key={index} className="thumbnail-wrapper">
                                <img src={image} alt="" className="thumbnail" />
                            <button type="button" onClick={() => removeImage(index)} className="remove-button">X</button>
                            <p>{index}</p>
                            </div>
                        ))} */}
                        {images.map((image, index) => (
                          <div key={index} className="thumbnail-wrapper">
                            { image == "[object File]" ? <img src={URL.createObjectURL(image)} alt="" className="thumbnail" /> : <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} alt={image} className="thumbnail" />}
                            <button type="button" onClick={() => removeImage(index)} className="remove-button">X</button>
                            <p>{index}</p>
                            </div>
                        ))}
                    </div>
            <div className="boxdash">
              <button type="submit" className='btn primary submitbtn2'>Publish Enquiry</button>
            </div>
          {/* </div> */}
              </form>
      </div>
    </section>
  )
}

export default EditComplaint
