// QuillEditor.js
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';

// Register Quill modules
Quill.register('modules/imageResize', ImageResize);

const QuillEditor = ({ value, onChange }) => {
  const [editorValue, setEditorValue] = useState(value);

  // Update the local state when the value prop changes
  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  // Handle editor value change
  const handleEditorChange = (newValue) => {
    setEditorValue(newValue);
    onChange(newValue);
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  return (
    <ReactQuill
      modules={modules}
      formats={formats}
      value={editorValue}
      onChange={handleEditorChange}
      style={{ height: '100%' }}
    />
  );
};

export default QuillEditor;
