import React, { useState, useEffect } from 'react'
import PostItem from '../components/PostItem'
import Loader from '../components/Loader'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const Statistics = () => {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const {category} = useParams()

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stats`)
        setPosts(response?.data)
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
    fetchPosts();
    console.log(posts)
  }, [category])

  if (isLoading) {
    return <Loader/>
  }

  return (
    // make inputs or fill in for digital profile bar grpah and pie chart.
    <section className="posts">
      {/* {posts.length > 0 ?
        <div className="container posts__container">
          {
            posts.map(({ _id: id, images, category, title, description, creator, createdAt }) =>
              <PostItem key={id} postID={id} thumbnail={images[0]} category={category} title={title} description={description} authorID={creator} createdAt={createdAt} moduleName="stats" />)
          }
        </div>: <h2 className='center'>No Posts found</h2>
} */}
      <h1 style={{textAlign: 'center'}}>Comming Soon!!</h1>
    </section>
  )
}

export default Statistics
