import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import Map from './Map'; // Import the Map component

const GoogleMapLoader = ({ lat, lng, name }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  console.log(name, "name")

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyCYrsWaiLthzrfGKTzb9xil7sEhqGwaZwE', // Replace with your actual API key
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load()
      .then(() => {
        setIsLoaded(true); // Google Maps is loaded
      })
      .catch(e => {
        console.error('Error loading Google Maps: ', e);
      });
  }, []);
  return isLoaded ? <Map lat={lat} lng={lng} name={name} /> : <div>Loading...</div>; // Render the map when loaded
};

export default GoogleMapLoader;
