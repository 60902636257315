import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AiOutlineClose } from 'react-icons/ai';
import { FaAddressBook, FaBars, FaBox, FaBriefcase, FaCalendarAlt, FaChartBar, FaEye, FaFrown, FaMap, FaPen, FaPencilAlt, FaPlus, FaStickyNote, FaUser, FaUsers } from 'react-icons/fa'; // Import the FaBars icon
import { UserContext } from '../context/userContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function AnchorTemporaryDrawer() {
  const { t } = useTranslation();

  const { currentUser } = React.useContext(UserContext)
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        '& .MuiListItemIcon-root': {
          minWidth: 'unset',
          marginRight: '16px', // Add margin to icons
        },
        '& .MuiButton-root': {
          width: '100%', // Make buttons full width
          justifyContent: 'start', // Align content to start
          textTransform: 'none', // Disable text transformation
          color: '#333', // Default icon color
          transition: 'color 0.3s', // Add transition for hover effect
          '&:hover': {
            color: '#ff5722', // Change color on hover
          },
        },
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
            { text: t('contact') , link: '/contacts', icon: <FaAddressBook /> },
            { text: t('stat'), link: 'digitalprofile', icon: <FaChartBar/>},
            { text: t('festival'), link: '/festivals', icon: <FaCalendarAlt/> },
            { text: t('destination'), link: '/destinations', icon: <FaMap/> },
            { text: t('product'), link: '/products', icon: <FaBox/> },
            { text: t('business'), link: '/businesses', icon: <FaBriefcase/> },
            { text: t('note'), link: '/notes', icon: <FaStickyNote/> },
            { text: t('complaint'), link: '/complaints', icon: <FaFrown/> },
              ].map((item, index) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton component="a" href={item.link} onClick={toggleDrawer('left', false)}>
                    <ListItemIcon>
                      {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
      </List>
      <Divider />
      {currentUser?.id && (
              <List>
              {[
                { text: currentUser?.name , link: `/profile/${currentUser.id}`, icon: <FaUser />  },
                // { text: 'View Posts', link: `/myposts/${currentUser.id}`, icon: <FaEye />},
                { text: 'View Posts', link: '/myposts', icon: <FaEye />},
                { text: 'Create Post', link: '/create', icon: <FaPlus /> },
                { text: 'Digital Profile', link: '/graph', icon: <FaChartBar/> },
                { text: 'Authors', link: '/authors', icon: <FaUsers /> },
              ].map((item, index) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton component="a" href={item.link} onClick={toggleDrawer('left', false)}>
                    <ListItemIcon>
                      {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
      )}


    </Box>
  );

  return (
    <div>
      <Button sx={{ color: '#000000', transition: 'color 0.3s', '&:hover': { color: '#ffffff' } }} onClick={toggleDrawer('left', true)}>
        {state.left ? <AiOutlineClose sx={{ fontSize: '32px' }} /> : <FaBars sx={{ fontSize: '32px' }} />} 
        {/* Use the icon components and apply styles */}
      </Button>
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
          {list('left')}
      </Drawer>
    </div>
  );
}
