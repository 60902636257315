import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../components/Loader';
import { useParams } from 'react-router-dom';
import ContactItem from './ContactItem';

const Contacts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { category } = useParams();

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/contacts`);
        // const response = await axios.get("https://visitlekam.onrender.com/api/contacts");
        
        setPosts(response?.data);

        // Extract categories from posts and store them in an array
        const uniqueCategories = Array.from(new Set(response?.data.map(post => post.category)));
        setCategories(uniqueCategories);
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    fetchPosts();
  }, [category]);

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredPosts = selectedCategory ? posts.filter(post => post.category === selectedCategory) : posts;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="posts">
      <div className="fixed-top">
        {/* <label htmlFor="category">Filter by Category: </label> */}
        <select id="category" value={selectedCategory} onChange={handleCategorySelect} className='contactFilter'>
          <option value="">All</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
      
      <div className='scrollable-content'>
      {filteredPosts.length > 0 ? (
        <div className="container contacts__container">
          {filteredPosts.map(({ _id: id, name, address, category, number1, number2, description, creator, createdAt }) => (
            <ContactItem key={id} postID={id} name={name} address={address} category={category} number1={number1} number2={number2} description={description} authorID={creator} createdAt={createdAt} moduleName="contacts" />
          ))}
        </div>
      ) : (
        <h2 className="center">No Posts found</h2>
      )}
      </div>
    </section>
  );
};

export default Contacts;
