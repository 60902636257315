import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { FaAddressBook, FaBox, FaBriefcase, FaCalendarAlt, FaChartBar, FaFrown, FaMap, FaStickyNote } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Layout = () => {
  const location = useLocation();
  const { t } = useTranslation();


  // Check if the current page is the dashboard
  const isDashboard = location.pathname === '/create';

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
           {/* White bar at the bottom for mobile */}
           <div className="white-bar-mobile">
        <div className="grid-bar">
          <Link to="/contacts" className="grid-item icon-container"><FaAddressBook /><span>{t('contact')}</span></Link>
          <Link to="/digitalprofile" className="grid-item icon-container"><FaChartBar /><span>{t('stat')}</span></Link>
          <Link to="/festivals" className="grid-item icon-container"><FaCalendarAlt /><span>{t('festival')}</span></Link>
          <Link to="/destinations" className="grid-item icon-container"><FaMap /><span>{t('destination')}</span></Link>
          <Link to="/products" className="grid-item icon-container"><FaBox /><span>{t('product')}</span></Link>
          <Link to="/businesses" className="grid-item icon-container"><FaBriefcase /><span>{t('business')}</span></Link>
          <Link to="/notes" className="grid-item icon-container"><FaStickyNote /><span>{t('note')}</span></Link>
          <Link to="/complaints" className="grid-item icon-container"><FaFrown /><span>{t('complaint')}</span></Link>
        </div>
      </div>
    </>
  );
};

export default Layout;
