import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Card = ({ postID, category, title, description, address, authorID, thumbnail, createdAt }) => {
  const shortDescription = description?.length > 145 ? description?.substr(0, 145) + '...' : description;
    const postTitle = title?.length > 30 ? title.substr(0, 30) + '...' : title;
  return (
    <Link to={`/destinations/${postID}`}>
      <div className="card">
      <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt={title} className="card-img imageClassHome" />
      <div className="card-content">
        <h4 className="card-name">{postTitle}</h4>
        <div className="card-location">
        <FaMapMarkerAlt />{address}
        </div>
        <button className="card-details-btn">Details</button>
      </div>
    </div>
    </Link>
  );
};

export default Card;
