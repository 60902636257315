import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

// Set up modal accessibility
Modal.setAppElement('#root');

const Popup = () => {
    const [modalIsOpen, setModalIsOpen] = useState(true); // Auto-open the modal
    const [post, setPost] = useState(null); // State to store the post data

    // Fetch the latest post data from the server when the component mounts
    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/popups/latest`); // API endpoint
                setPost(response.data); // Store the fetched post
                console.log(response.data); // Log the response for debugging
            } catch (error) {
                console.error('Error fetching the image:', error);
            }
        };

        fetchImage();
    }, []);

    const closeModal = () => {
        setModalIsOpen(false); // Close the modal when the user clicks close
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ASSETS_URL}/uploads/${post.thumbnail}`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', post.thumbnail); // Set the filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Clean up
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <div>
            {/* Modal for showing the image */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="First Image Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button onClick={closeModal} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '12px', color: '#333', textAlign: 'right', flex: 1 }}>X</button>
                </div>

                {/* Display the image if it exists */}
                {post ? (
                    <div>
                        <img
                            src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${post.thumbnail}`}
                            alt={post.title || "First Uploaded"}
                            style={{ width: '100%', height: 'auto', maxHeight: '600px', marginTop: '2px' }}
                        />
                        <button
                            onClick={handleDownload}
                            style={{ display: 'block', marginTop: '10px', textAlign: 'center', color: '#007BFF', border: 'none', background: 'none', cursor: 'pointer' }}
                        >
                            Download
                        </button>
                    </div>
                ) : (
                    <p>Loading image...</p> // Loading state while fetching the image
                )}
            </Modal>
        </div>
    );
};

export default Popup;
