import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Image1 from '../images/img2.png';
import Image2 from '../images/img1.png';
import Loader from './Loader';
import axios from 'axios';
import CardImage from './CardImage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const MidBanner = () => {

  const { t } = useTranslation();


  //  to fetch posts
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/festivals`)
        setPosts(response?.data)
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
    fetchPosts();
  }, [])

  if (isLoading) {
    return <Loader/>
  }


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    // tablet: {
    //   breakpoint: { max: 1024, min: 464 },
    //   items: 2,
    //   slidesToSlide: 2 // optional, default to 1.
    // },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    }
  };  


    return (
      
  <>
      <div className='crousel-container'>
          <h2>{t('explorefestival')}</h2>
          <p>{ t('explorefestivaldetail')}</p>
          <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={3000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
          >
            {/*  show images of hotels.destonations */}
            {posts.map(({ _id: id, thumbnail, category, title, description, creator, createdAt }) => (
        
  
              <CardImage key={id} postID={id} thumbnail={thumbnail} category={category} title={title} description={description} authorID={creator} createdAt={createdAt} />
      ))}
    </Carousel>
      </div>
      
      {/* //   second mid banner */}
      <div className="container-banner">
        <div className="row-banner">
          <div className="column-banner">
              <h2>{t('bannerH')}</h2>
              <p>{t('bannerpara')}</p>
                              <div>
                <Link to="/destinations"><button className="btn">{t('destination')}</button></Link>
                              <Link to="/contacts"><button className="btn">{t('contact')}</button></Link>
              </div>
          </div>
          <div className="column-banner">
            <img src={Image1} alt="Image 1" className='imageClass hideImage'/>
          </div>
        </div>
        <div className="row-banner">
          <div className="column-banner">
            <img src={Image2} alt="Image 2" className='imageClass '/>
          </div>
          <div className="column-banner">
              <h2>{t('bannerH2')}</h2>
              <p>{t('bannerpara2')}</p>
              <div>
                <Link to="/products"><button className="btn">{t('product')}</button></Link>
                <Link to="/businesses"> <button className="btn">{t('business')}</button></Link>
              </div>
          </div>
        </div>
      </div>
            </>
  );
};

export default MidBanner;
