import React from 'react';
import { Link } from 'react-router-dom';
import PostAuthor from './PostAuthor';

const PostItem = ({ postID, category, title, description, authorID, thumbnail, createdAt, moduleName, address }) => {
    const removeHTMLTags = (str) => {
        return str.replace(/<[^>]*>?|&nbsp;/gm, '');
    };

    const shortDescription = description?.length > 145 ? removeHTMLTags(description?.substr(0, 100)) + '...' : removeHTMLTags(description);
    const postTitle = title.length > 80 ? title.substr(0, 80) + '...' : title;
    
    return (
        <article className="post">
            <Link to={`/${moduleName}/${postID}`} className="post__link">
                {category && (<div className="business-category-ribbon">{category}</div>)}
                <div className="post__thumbnail">
                    <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt={title} className='imageClass'/>
                </div>
                <div className="post_content">
                    <h4>{postTitle}</h4>
                    <h5 className='business-address'>{address}</h5>
                    <h5 dangerouslySetInnerHTML={{__html: shortDescription}}/>
                    <div className="post__footer">
                        {/* <PostAuthor authorID={authorID} createdAt={createdAt} /> */}
                        {/* <Link to={`/posts/categories/${category}`} className='btn category'>{ category }</Link> */}
                    </div>
                </div>
            </Link>
        </article>
    );
}

export default PostItem;
