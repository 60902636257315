import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/userContext';
import axios from 'axios';
import Loader from '../components/Loader';

// const DeletePost = ({ postId: id, moduleName: moduleName }) => {
  const DeletePost = ({ postId: id, moduleName, setPosts, posts }) => {

  console.log("", id, moduleName);

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  // Redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const removePost = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this post?");
    if (!confirmed) {
      return;
    }

    console.log("We can reach to delete");
    setIsLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/${moduleName}/${id}`, { 
        withCredentials: true, 
        headers: { Authorization: `Bearer ${token}` } 
      });
      if (response.status === 200) {
        setPosts(posts.filter((post) => post._id !== id));
        if (location.pathname === `/myposts/${currentUser.id}`) {
          navigate('/myposts');
        } else {
          navigate('/myposts');
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Couldn't delete post");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Link className='btn sm danger' onClick={removePost}>Delete</Link>
  );
}

export default DeletePost;
