import React from 'react'
import { MyBarChart, MyPieChart} from './Recharts'
//  for victory
// import { MyBarChart, MyPieChart} from './ReactCharts'


const Charts = () => {
  return (
    <div>
          <MyBarChart />
          <MyPieChart/>
    </div>
  )
}

export default Charts
