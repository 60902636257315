import 'react-quill/dist/quill.snow.css'
import { UserContext } from '../../context/userContext'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import Loader from '../../components/Loader'



const CreateComplaint = () => {
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [subject, setSubject] = useState('')
  const [complaint, setComplaint] = useState('')
  const [images, setImages] = useState([])
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])




  const createComplaint = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const postData = new FormData();
    postData.set('to', to)
    postData.set('from', from)
    postData.set('subject', subject)
    postData.set('complaint', complaint)
    for (let i = 0; i < images.length; i++) {
      postData.append('images', images[i]);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/complaints`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 201) {
        return navigate('/create')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'images'") {
        setError("Please choose images.")
      } else {
        setError(err.response.data.message);
      }
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return <Loader/>
  }

  
  // to handle array of images
  const handleImageChange = (event) => {
    const files = event.target.files;
    // Convert the FileList object to an array
    const fileList = Array.from(files);
    // Update the images state with the array of selected files
    setImages(fileList);
  };

  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={createComplaint}>
          <input type='text' placeholder='To' value={to} onChange={e => setTo(e.target.value)} autoFocus />
          <input type='text' placeholder='From' value={from} onChange={e => setFrom(e.target.value)} autoFocus />
          <input type='text' placeholder='Subject' value={subject} onChange={e => setSubject(e.target.value)} autoFocus />
          <textarea type='text' placeholder='Enter your complaint here....' value={complaint} onChange={e => setComplaint(e.target.value)} autoFocus className="expandable-textarea" />
          {/* <div class="container-quill"> */}
          <div className="thumbnaildash2">
              <span>Choose Images or Files : </span>
              <input type="file" onChange={handleImageChange} multiple/>
          </div>
            <div className="boxdash">
              <button type="submit" className='btn primary submitbtn2'>Publish Enquiry</button>
            </div>
          {/* </div> */}
            </form>
      </div>
    </section>
  )
}

export default CreateComplaint;

