import { UserContext } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import QuillEditor from '../../components/QuillEditor';
import Loader from '../../components/Loader';

const CreateProduct = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [nestedFields, setNestedFields] = useState([{ phone1: '', phone2: '', name: '', price: '', unit: '', address: '' }]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedNestedFields = [...nestedFields];
    updatedNestedFields[index][name] = value;
    setNestedFields(updatedNestedFields);
  };

  const handleAddFields = () => {
    setNestedFields([...nestedFields, { phone1: '', phone2: '', name: '', price: '', unit: '', address: '' }]);
  };

  const handleRemoveFields = (index) => {
    const updatedNestedFields = [...nestedFields];
    updatedNestedFields.splice(index, 1);
    setNestedFields(updatedNestedFields);
  };

  const createProduct = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const postData = new FormData();
    postData.set('title', title);
    postData.set('description', description);
    postData.set('thumbnail', thumbnail);
    postData.set('nestedFields', JSON.stringify(nestedFields));

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/products`, postData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        return navigate('/create');
      }
    } catch (err) {
      if (err.response?.data?.message === "TypeError: Cannot read properties of null (reading 'thumbnail')") {
        setError("Please choose a thumbnail.");
      } else {
        setError(err.response?.data?.message || "An error occurred.");
      }
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="create-post">
      <div className="container">
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-post__form" onSubmit={createProduct}>
          <input type='text' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus required />
          <div className="thumbnaildash">
            <span>Choose thumbnail : </span>
            <input type="file" onChange={e => setThumbnail(e.target.files[0])} accept='image/png, image/jpg, image/jpeg' required />
          </div>
          <div style={{ height: '40vh' }}>
            <QuillEditor value={description} onChange={setDescription} />
          </div>
          <h3 className='seller_heading'>Contact of Sales</h3>
          {nestedFields.map((field, index) => (
            <div key={index} className='form'>
              <input type='text' placeholder='Phone 1' name='phone1' value={field.phone1} onChange={e => handleChange(index, e)} required />
              <input type='text' placeholder='Phone 2' name='phone2' value={field.phone2} onChange={e => handleChange(index, e)} />
              <input type='text' placeholder='Name' name='name' value={field.name} onChange={e => handleChange(index, e)} />
              <input type='number' placeholder='Price' name='price' value={field.price} onChange={e => handleChange(index, e)} />
              <input type='text' placeholder='Unit' name='unit' value={field.unit} onChange={e => handleChange(index, e)} />
              <input type='text' placeholder='Address' name='address' value={field.address} onChange={e => handleChange(index, e)} />
              {/* <button type="button" onClick={() => handleRemoveFields(index)}>Remove</button> */}
              {/* <Box sx={{ '& button': { m: 1 } }}>
                <div>
                  <Button size="small" onClick={() => handleRemoveFields(index)}>Remove</Button>
                </div>
              </Box> */}
               <div className="container-seller">
                  <button type="button" className="minus-button" onClick={() => handleRemoveFields(index)}>
                      <span className="cross-icon">✖</span>
                      {/* REMOVE */}
                  </button>
              </div>
            </div>
          ))}
          {/* <button type="button" onClick={handleAddFields}>Add More Fields</button> */}
          <div className="container-seller">
            <button type="button" className="add-button"  onClick = { handleAddFields }>
                <span className="cross-icon">✚</span>
                ADD SELLER
            </button>
          </div>
          <div className="boxdash">
            <button type="submit" className='btn primary submitbtn'>Publish Product</button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateProduct;
