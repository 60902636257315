import React, { useContext, useEffect, useState } from 'react'
import { DUMMY_POSTS } from '../data'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../context/userContext'
import axios from 'axios'
import Loader from '../components/Loader'
import DeletePost from '../pages/DeletePost'

import ButtonComponent from './ButtonComponent'
import { FaAddressBook, FaChartBar, FaFrown, FaMapMarkerAlt, FaPhone, FaStickyNote } from 'react-icons/fa'

const Dashboard = () => {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [moduleName, setModuleName] = useState();
  const { id } = useParams()
  console.log(id, useParams())

  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

    //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [])
  
  useEffect(() => {
    const fetchPosts = async () => {
      setModuleName("products")
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/products`)
        setPosts(response.data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchPosts();
  }, [id])
  // Function to handle button click
  const handleButtonClick = async (buttonName) => {
    setModuleName(buttonName)
    console.log("Button clicked:", buttonName);
    // setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${buttonName}`);
      setPosts(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  if (isLoading) {
    return <Loader/>
  }



  return (
      <section className="dashboard">
      <ButtonComponent onButtonClick={handleButtonClick}/>
      {
        posts.length ? <div className="container dashboard__container">
          {
            posts.map(post => {
              //  for title
              let title = moduleName;
              if (moduleName === "businesses") {
                title = post.name;
              } else if (moduleName === "complaints") {
                title = post.to;
              } else if (moduleName === "contacts") {
                title = post.name;
              } else {
                title = post.title;
              }

              // for image
              let content = ""
              if (moduleName === "contacts") {
                content = <FaAddressBook/>
              } else if (moduleName === "complaints") {
                content = <FaFrown/>
              } else if (moduleName === "stats") {
                content = <FaChartBar/>
              } else if (moduleName === "notes") {
                content = <FaStickyNote/>
              } else {
                content = <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${post.thumbnail}`} alt="" className='imageClass'/>
                  
              }

              return <article key={post.id} className="dashboard__post">
                <div className="dashboard__post-info">
                  <div className="dashboard__post-thumbnail icon-container">
                    {content} 
                  </div>
                  <h5>{title}</h5>
                  {moduleName === "contacts" ?
                    <h5>
                      |
                      <div className="icon-business"><FaMapMarkerAlt /></div> 
                      <span className='dash-contacts'>{post.address}</span> |
                      <div className="icon-business"><FaPhone /></div> 
                      {post.number1}, {post.number2}
                    </h5> : ""}
                </div>
                <div className="dashboard__post-actions">
                  <div className="dashboard__post-actions">
                  {moduleName === "businesses" && <h5 className='dash-cat'>{ post.category}</h5> }
                    {moduleName != "contacts" ? <Link to={`/${moduleName}/${post._id}`} className='btn sm'>View</Link> : <h5 className='dash-cat'>{post.category}</h5>}
                    {moduleName != "complaints" ? <Link to={`/${moduleName}/${post._id}/edit`} className='btn sm primary'>Edit</Link> : ""}
                    
                    {/* <DeletePost postId={post._id} moduleName={moduleName} /> */}
                    <DeletePost postId={post._id} moduleName={moduleName} setPosts={setPosts} posts={posts} />

                  </div>
                </div>
              </article>
            })

          }
        </div> : <h2 className='center'>You have no posts yet.</h2>
      }
    </section>
  )
}

export default Dashboard
