import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import axios from 'axios'
import '../../styles/dashboard.css';
import Loader from '../../components/Loader';



const EditBusiness = () => {
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [contact_no, setContact_no] = useState('')
    const [description, setDescription] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [category, setCategory] = useState('Uncategorized')
    const [thumbnail, setThumbnail] = useState('')
    const [error, setError] = useState('')
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false)



  const navigate = useNavigate();
  const { id } = useParams();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]


  // to fetch post data

  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/businesses/${id}`)
        setName(response.data.name)
        setAddress(response.data.address)
          setContact_no(response.data.contact_no)
          setDescription(response.data.description)
          setLatitude(response.data.latitude)
          setLongitude(response.data.longitude)
          setCategory(response.data.category)
          setThumbnail(response.data.thumbnail)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    getPost();
  }, [])

  if (isLoading) {
    return <Loader/>
  }


  // to edit post
  const editBusiness = async (e ) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('name', name)
    postData.set('address', address)
    postData.set('contact_no', contact_no)
    postData.set('description', description)
    postData.set('latitude', latitude)
    postData.set('longitude', longitude)
    postData.set('category', category)
    postData.set('thumbnail', thumbnail)

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/businesses/${id}`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 200) {
        return navigate('/myposts')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a thumbnail.")
      } else {
        setError(err.response.data.message);
      }
    }
  }

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);

    // Create a URL for the selected file
    const imageUrl = URL.createObjectURL(file);
    setThumbnailUrl(imageUrl);
  };
    
  const POST_CATEGORIES = ["Hotel", "Restaurant", "Shop", "Uncategorized"]

  


  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={editBusiness}>
            <input type='text' placeholder='Name' value={name} onChange={e => setName(e.target.value)} autoFocus />
            <input type='text' placeholder='Address' value={address} onChange={e => setAddress(e.target.value)} autoFocus />
            <input type='text' placeholder='Contact Number' value={contact_no} onChange={e => setContact_no(e.target.value)} autoFocus />
            <input type='text' placeholder='Latitude' value={latitude} onChange={e => setLatitude(e.target.value)} autoFocus />
            <input type='text' placeholder='Longitude' value={longitude} onChange={e => setLongitude(e.target.value)} autoFocus />
          {/* <div class="container-quill"> */}
            <div className="box"> <select name='category' value={category} onChange={e => setCategory(e.target.value)}>
            {
              POST_CATEGORIES.map(cat => <option key={cat}>{cat}</option>)
            }
          </select></div>
  {/* Display thumbnail image if selected */}
            <div className="thumbnaildash">
            <input type="file" onChange={handleThumbnailChange} accept='image/*' className="custom-file-input"/>

            {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" className="thumbnail"/>}
            {thumbnail.length > 0 && (
              <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt="" className="thumbnail"/>
            )}
          </div>
          
          {/* </div> */}
         
          <div style={{ height: '40vh' }}> {/* Set height of parent container */}
            <ReactQuill modules={modules} formats={formats} value={description} onChange={setDescription} style={{ height: '100%' }} /> {/* Set height of ReactQuill */}
          </div>
          <div className="boxdash">
              <button type="submit" className='btn primary submitbtn'>Publish Business</button>
            </div>
        </form>
      </div>
    </section>
  )
}

export default EditBusiness
