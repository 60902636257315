import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartIcon from '@mui/icons-material/TableChart';

const Graph = ({ graph }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    const initializeChart = () => {
      if (!graph || !graph.data || !graph.data.length) {
        setChartOptions({});
        setChartSeries([]);
        return;
      }

      switch (graph.type) {
        case 'pie':
        case 'donut':  // This case now covers both pie and donut charts
          setChartOptions({
            chart: {
              type: 'donut', // Change to 'donut' for a donut chart
            },
            labels: graph.data.map(item => item.label),
            responsive: [
              {
                breakpoint: 600,
                options: {
                  chart: {
                    width: '100%',
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          });
          setChartSeries(graph.data.map(item => item.value));
          break;
        case 'bar':
        case 'groupedBar':
          setChartOptions({
            chart: {
              type: 'bar',
            },
            xaxis: {
              categories: graph.data.map(item => item.label),
            },
            responsive: [
              {
                breakpoint: 600,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: true,
                    },
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          });
          setChartSeries([
            {
              name: 'Value',
              data: graph.data.map(item => item.value),
            },
          ]);
          break;
        case 'line':
          setChartOptions({
            chart: {
              type: 'line',
            },
            xaxis: {
              categories: graph.data.map(item => item.category),
            },
            responsive: [
              {
                breakpoint: 600,
                options: {
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          });
          setChartSeries(
            Object.keys(graph.data[0] || {})
              .filter(key => key !== 'category')
              .map(key => ({
                name: key,
                data: graph.data.map(item => item[key]),
              }))
          );
          break;
        default:
          break;
      }
    };

    initializeChart();
  }, [graph]);

  // Function to get appropriate icon with rounded background
  const getIcon = (type) => {
    let icon;
    let backgroundColor;
    let iconColor = '#fff';

    switch (type) {
      case 'pie':
      case 'donut':  // Cover both pie and donut types
        icon = <PieChartIcon />;
        backgroundColor = '#f44336'; 
        break;
      case 'bar':
      case 'groupedBar':
        icon = <BarChartIcon />;
        backgroundColor = '#3f51b5'; 
        break;
      case 'line':
        icon = <ShowChartIcon />;
        backgroundColor = '#4caf50'; 
        break;
      case 'raw':
        icon = <TableChartIcon />;
        backgroundColor = '#ff9800';
        break;
      default:
        return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: backgroundColor,
          color: iconColor,
          marginRight: '10px',
        }}
      >
        {icon}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        marginBottom: '20px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Title Section with Icon */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {getIcon(graph.type)}
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#1fa194', 
            fontWeight: 'bold', 
            fontSize: { xs: '16px', sm: '18px', md: '20px' }
          }}
        >
          {graph.title}
        </Typography>
      </Box>

      {/* Chart Rendering Based on Type */}
      {['pie', 'donut', 'bar', 'groupedBar', 'line'].includes(graph.type) && chartSeries.length > 0 && (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type={chartOptions.chart?.type}
          width="100%"
          height={400}
        />
      )}

      {/* For raw data */}
      {graph.type === 'raw' && graph.data && graph.data.length > 0 && (
        <Box sx={{ paddingTop: '10px' }}>
          {graph.data.map((entry, index) => (
            <Typography 
              key={index} 
              variant="body1" 
              sx={{ 
                color: '#000', 
                marginBottom: '5px',
                fontSize: { xs: '14px', sm: '16px' }
              }}
            >
              <strong>{entry.label}:</strong> {entry.value}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Graph;
