import React, { useContext, useEffect, useState } from 'react'
import PostAuthor from '../components/PostAuthor'
import { Link, useParams } from 'react-router-dom'
import Thumbnail from "../images/blog1.jpg"
import { UserContext } from '../context/userContext'
import DeletePost from './DeletePost'
import Loader from '../components/Loader'
import axios from 'axios'

const PostDetail = () => {
  const { id, moduleName } = useParams()
  console.log(id, moduleName)
  
  const [post, setPost] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { currentUser } = useContext(UserContext)

  //  fetch post 
  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${moduleName}/${id}`)
        setPost(response?.data)
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    }
    getPost();
  },[])


  
  if (isLoading) {
    return <Loader/>
  }


  return (
    <section className="post-detail">
      {error && <p className='error'>{ error }</p>}
      {post && <div className="container post-detail__container">
        {/* <div className="post-detail__header"> */}
          {/* <PostAuthor authorID={post.creator} createdAt={post.createdAt}/> */}
         {/* {currentUser?.id == post?.creator &&  <div className="post-detail__buttons">
            <Link to={`/posts/${post?._id}/edit`} className='btn sm primary'>Edit</Link>
            <DeletePost postId={id} />
           </div> } */}
        {/* </div> */}
        <h1 className='post_deatail-heading'>{post.title}</h1>
        <div className="post-detail__thumbnail">
          <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${post.thumbnail}`} alt="" className='imageClass'/>
        </div>
        <p dangerouslySetInnerHTML={{__html: post.description}}></p>
      </div>}
    </section>
  )
}

export default PostDetail
