import React, { useContext, useEffect, useState } from 'react'
import PostAuthor from '../components/PostAuthor'
import { Link, useParams } from 'react-router-dom'
import Thumbnail from "../images/blog1.jpg" // Not used, can be removed
import { UserContext } from '../context/userContext'
import DeletePost from './DeletePost'
import Loader from '../components/Loader'
import axios from 'axios'

const NoteDetail = () => {
  const { id, moduleName } = useParams()
  console.log(id, moduleName)
  
  const [post, setPost] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { currentUser } = useContext(UserContext)

  //  fetch post 
  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/notes/${id}`)
        setPost(response?.data)
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    }
    getPost();
  }, [])
    
  // Function to format date to display only date part
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }
  

  if (isLoading) {
    return <Loader/>
  }

  return (
    <section className="post-detail">
      {error && <p className='error'>{ error }</p>}
      {post && <div className="container post-detail__container">
        {/* <div className="post-detail__header"> */}
          {/* <PostAuthor authorID={post.creator} createdAt={post.createdAt}/> */}
          {/* {currentUser?.id == post?.creator &&  
            <div className="post-detail__buttons">
              <Link to={`/posts/${post?._id}/edit`} className='btn sm primary'>Edit</Link>
              <DeletePost postId={id} />
            </div> 
          } */}
        {/* </div> */}
        <h1 className='post_deatail-heading'>{post.title}</h1>
        <h5 className='post_deatail-heading'>Published date: {formatDate(post.createdAt)}</h5>
        <div className="notes_images">
          {post.images && post.images.map((image, index) => (
            <img key={index} src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} alt={`Image ${index}`} className='imageClass notes_images'/>
          ))}
        </div>
        <p dangerouslySetInnerHTML={{__html: post.description}}></p>
      </div>}
    </section>
  )
}

export default NoteDetail
