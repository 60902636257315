import React, { useState } from 'react';

function YouTubeVideo() {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const loadVideo = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className='youtube-video' style={{ width: '100%', height: '500px', position: 'relative' }}>
      {!isVideoLoaded ? (
        <div
          onClick={loadVideo}
          style={{
            cursor: 'pointer',
            backgroundImage: `url(https://img.youtube.com/vi/BTubTzMb5VA/hqdefault.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          {/* Custom Play Button Styled to Look Like YouTube's with Hover Effects */}
          <div
            className='play-button'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'rgba(0, 0, 0, 0.7)',
              borderRadius: '50%',
              width: '80px',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1.1)';
              e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 0, 0, 0.6)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1)';
              e.currentTarget.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.3)';
            }}
          >
            <div
              style={{
                width: '0',
                height: '0',
                borderLeft: '18px solid white',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
              }}
            ></div>
          </div>
        </div>
      ) : (
        <iframe
          width="100%"
          height="500"
          className='youtube-iframe'
          src={`https://www.youtube.com/embed/BTubTzMb5VA?autoplay=1&si=eaxSAv0_CDj0z-Ao`}
          title="Welcome to Lekam"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
}

export default YouTubeVideo;
