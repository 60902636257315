import { UserContext } from '../../context/userContext'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import QuillEditor from '../../components/QuillEditor'
import Loader from '../../components/Loader'




const CreateBusiness = () => {
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [contact_no, setContact_no] = useState('')
  const [description, setDescription] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [category, setCategory] = useState('Uncategorized')
  const [thumbnail, setThumbnail] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;
  

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])


  const POST_CATEGORIES = ["Hotel", "Restaurant", "Shop", "Uncategorized"]

  const createBusiness = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const postData = new FormData();
    postData.set('name', name)
    postData.set('address', address)
    postData.set('contact_no', contact_no)
    postData.set('description', description)
    postData.set('latitude', latitude)
    postData.set('longitude', longitude)
    postData.set('category', category)
    postData.set('thumbnail', thumbnail)

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/businesses`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 201) {
        return navigate('/create')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a thumbnail.")
      } else {
        setError(err.response.data.message);
      }
    }
    setIsLoading(false);
  }
  
  if (isLoading) {
    return <Loader/>
  }

  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={createBusiness}>
            <input type='text' placeholder='Name' value={name} onChange={e => setName(e.target.value)} autoFocus />
            <input type='text' placeholder='Address' value={address} onChange={e => setAddress(e.target.value)} autoFocus />
            <input type='text' placeholder='Contact Number' value={contact_no} onChange={e => setContact_no(e.target.value)} autoFocus />
            <input type='text' placeholder='Latitude' value={latitude} onChange={e => setLatitude(e.target.value)} autoFocus />
            <input type='text' placeholder='Longitude' value={longitude} onChange={e => setLongitude(e.target.value)} autoFocus />
            <div className="box"> <select name='category' value={category} onChange={e => setCategory(e.target.value)}>
            {
              POST_CATEGORIES.map(cat => <option key={cat}>{cat}</option>)
            }
            </select>
            </div>
          <div className="thumbnaildash">
          <span>Choose thumbnail : </span>

              <input type="file" onChange={e => setThumbnail(e.target.files[0])} accept='png, jpg, jpeg' />
            </div>
          
          {/* <div class="container-quill"> */}

          <div style={{ height: '40vh' }}> {/* Set height of parent container */}
            <QuillEditor value={description} onChange={setDescription} />
          </div>
          {/* </div> */}
            
          <div className="boxdash">
              <button type="submit" className='btn primary submitbtn'>Publish Business</button>
            </div>
        </form>
      </div>
    </section>
  )
}

export default CreateBusiness;

