import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhone, FaUser } from 'react-icons/fa';

const ContactItem = ({ postID, name, address, category, number1, number2, description, authorID, createdAt, moduleName }) => {
    return (
        <article className="contacts post-contacts">
            <div className="container-contact">
                <div className="left-section">
                    <div className="icon-phone">
                        <FaUser /> {/* User Icon */}
                    </div>
                    <div className="text-container-contact">
                        <h4>{name}</h4> {/* User's Name */}
                        <h5>{address}</h5>
                        <h6>{category}</h6> {/* User's Category */}
                        <h6 className='contact_desc'>{description}</h6>
                    </div>
                </div>
                <div className="right-section">
                    <a href={`tel:${number1}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="row_contacts">
                            <div className="text-container-contact">
                            <h5>{number1}</h5>
                            </div>
                            <div className='icon-container-contact'>
                                <FaPhone /> {/* Phone Icon */}
                            </div>
                        </div>
                    </a>    
                    {number2 &&
                    <a href={`tel:${number2}`} style={{ textDecoration: 'none', color: 'inherit' }}>  
                        <div className="row_contacts">
                            <div className="text-container-contact">
                                <h5>{number2}</h5>
                            </div>
                            <div className='icon-container-contact'>
                                <FaPhone /> {/* Phone Icon */}
                            </div>
                        </div>
                    </a>    
                    }
                </div>
            </div>
        </article>
    );
}

export default ContactItem;
