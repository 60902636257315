import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import axios from 'axios'
import Loader from '../../components/Loader'


const EditDestination = () => {
  const [title, setTitle] = useState('')
  const [address, setAddress] = useState('')
  const [description, setDescription] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [error, setError] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const navigate = useNavigate();
  const { id } = useParams();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]


  // to fetch post data

  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/destinations/${id}`)
        setTitle(response.data.title)
        setAddress(response.data.address)
        setDescription(response.data.description)
        setLatitude(response.data.latitude)
        setLongitude(response.data.longitude)
        setThumbnail(response.data.thumbnail)
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false);
    }
    getPost();
  }, [])

  if (isLoading) {
    return <Loader/>
  }



  // to edit post
  const editProduct = async (e ) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('title', title)
    postData.set('address', address)
    postData.set('description', description)
    postData.set('latitude', latitude)
    postData.set('longitude', longitude)
    postData.set('thumbnail', thumbnail)

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/destinations/${id}`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 200) {
        return navigate('/myposts')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a thumbnail.")
      } else {
        setError(err.response.data.message);
      }
    }
  }

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);

    // Create a URL for the selected file
    const imageUrl = URL.createObjectURL(file);
    setThumbnailUrl(imageUrl);
  };

  return (
    <section className="create-post">
      <div className="container">
        <h2>Edit Destination</h2>
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={editProduct}>
          <input type='text' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
          <input type='text' placeholder='Address' value={address} onChange={e => setAddress(e.target.value)} autoFocus />
          <input type='text' placeholder='Latitude' value={latitude} onChange={e => setLatitude(e.target.value)} autoFocus />
          <input type='text' placeholder='Longitude' value={longitude} onChange={e => setLongitude(e.target.value)} autoFocus />
          <div className='thumbnail-container'>
            <input type="file" onChange={handleThumbnailChange} accept='image/*' className="custom-file-input"/>
            {/* Display thumbnail image if selected */}
            <div>
              {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" className="thumbnail"/>}
              {thumbnail.length > 0 && (
                <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt="" className="thumbnail"/>
              )}
          </div>
          </div>
          <div style={{ height: '40vh' }}>
            <ReactQuill modules={modules} formats={formats} value={description} onChange={setDescription} style={{ height: '100%' }}/>
          </div>
          <div className="boxdash">
            <button type="submit" className='btn primary submitbtn'>Update Destination</button>
            </div>
        </form>
      </div>
    </section>
  )
}

export default EditDestination
