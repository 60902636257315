import React, { useContext, useEffect, useState } from 'react'
import PostAuthor from '../components/PostAuthor'
import { Link, useParams } from 'react-router-dom'
import Thumbnail from "../images/blog1.jpg"
import { UserContext } from '../context/userContext'
import DeletePost from './DeletePost'
import Loader from '../components/Loader'
import axios from 'axios'
import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa'
import Map from '../components/Map'
import GoogleMapLoader from '../components/GoogleMapLoader'

const BusinessDetail = () => {
  const { id, moduleName } = useParams()
  console.log(id, moduleName)
  
  const [post, setPost] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { currentUser } = useContext(UserContext)

  //  fetch post 
  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/businesses/${id}`)
        setPost(response?.data)
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    }
    getPost();
  },[])


  
  if (isLoading) {
    return <Loader/>
  }


  return (
    <section className="post-detail">
      {error && <p className='error'>{ error }</p>}
      {post && <div className="container post-detail__container">
        {/* <div className="post-detail__header"> */}
          {/* <PostAuthor authorID={post.creator} createdAt={post.createdAt}/> */}
         {/* {currentUser?.id == post?.creator &&  <div className="post-detail__buttons">
            <Link to={`/posts/${post?._id}/edit`} className='btn sm primary'>Edit</Link>
            <DeletePost postId={id} />
           </div> } */}
        {/* </div> */}
        <h1 className='post_deatail-heading'>{post.name}</h1>
        <div className="post-detail__thumbnail" style={{ position: 'relative' }}>
          <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${post.thumbnail}`} alt="" className='imageClass'/>
          <div className="ribbon-text-business">
            {post.category}
          </div>
          <div className="overlay-text-business">
            <div>
              <div className="icon-business"><FaMapMarkerAlt /></div> 
              {post.address}
            </div>
            <div>
              <div className="icon-business"><FaPhone /></div> 
              {post.contact_no}
            </div>
          </div>
        </div>
        <p dangerouslySetInnerHTML={{ __html: post.description }}></p>
        <div className='businessMap'>
          <GoogleMapLoader lat={post.latitude} lng={post.longitude} name={post.name} />
        </div>
      </div>}
    </section>
  )
}

export default BusinessDetail
