import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import Loader from '../../components/Loader';

const EditProduct = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [nestedFields, setNestedFields] = useState([{ phone1: '', phone2: '', name: '', price: '', unit: '', address: '' }]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  // Fetch product data
  useEffect(() => {
    const getProduct = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/${id}`);
        const product = response.data;
        setTitle(product.title);
        setDescription(product.description);
        setThumbnail(product.thumbnail);
        setNestedFields(product.nestedFields);
      } catch (error) {
        console.log(error);
        setError("Failed to fetch product data.");
      }
      setIsLoading(false);
    };
    getProduct();
  }, [id]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedNestedFields = [...nestedFields];
    updatedNestedFields[index][name] = value;
    setNestedFields(updatedNestedFields);
  };

  const handleAddFields = () => {
    setNestedFields([...nestedFields, { phone1: '', phone2: '', name: '', price: '', unit: '', address: '' }]);
  };

  const handleRemoveFields = (index) => {
    const updatedNestedFields = [...nestedFields];
    updatedNestedFields.splice(index, 1);
    setNestedFields(updatedNestedFields);
  };

  const editProduct = async (e) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('title', title);
    postData.set('description', description);
    postData.set('thumbnail', thumbnail);
    postData.set('nestedFields', JSON.stringify(nestedFields));

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/products/${id}`, postData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        return navigate('/myposts');
      }
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);

    // Create a URL for the selected file
    const imageUrl = URL.createObjectURL(file);
    setThumbnailUrl(imageUrl);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="create-post">
      <div className="container">
        <h2>Edit Product</h2>
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-post__form" onSubmit={editProduct}>
          <input type='text' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
          <div className='thumbnail-container'>
            <input type="file" onChange={handleThumbnailChange} accept='image/*' className="custom-file-input" />
            {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" className="thumbnail" />}
            {thumbnail.length > 0 && (
              <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt="" className="thumbnail" />
            )}
          </div>
          <div style={{ height: '40vh' }}>
            <ReactQuill modules={modules} formats={formats} value={description} onChange={setDescription} style={{ height: '100%' }} />
          </div>
          <h3 className='seller_heading'>Contact of Sales</h3>
          {nestedFields.map((field, index) => (
            <div key={index} className='form'>
              <input type='text' placeholder='Phone 1' name='phone1' value={field.phone1} onChange={e => handleChange(index, e)} required />
              <input type='text' placeholder='Phone 2' name='phone2' value={field.phone2} onChange={e => handleChange(index, e)} />
              <input type='text' placeholder='Name' name='name' value={field.name} onChange={e => handleChange(index, e)} />
              <input type='number' placeholder='Price' name='price' value={field.price} onChange={e => handleChange(index, e)} />
              <input type='text' placeholder='Unit' name='unit' value={field.unit} onChange={e => handleChange(index, e)} />
              <input type='text' placeholder='Address' name='address' value={field.address} onChange={e => handleChange(index, e)} />
              {/* <button type="button" onClick={() => handleRemoveFields(index)}>Remove</button> */}
              <div className="container-seller">
                  <button type="button" className="minus-button" onClick={() => handleRemoveFields(index)}>
                      <span className="cross-icon">✖</span>
                      {/* REMOVE */}
                  </button>
              </div>
            </div>
          ))}
          {/* <button type="button" onClick={handleAddFields}>Add More Fields</button> */}
          <div className="container-seller">
            <button type="button" className="add-button"  onClick = { handleAddFields }>
                <span className="cross-icon">✚</span>
                ADD SELLER
            </button>
          </div>
          <div className="boxdash">
            <button type="submit" className='btn primary submitbtn'>Update Product</button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditProduct;
