// MainComponent.js
import React, { useEffect, useRef, useState } from 'react';
import Card from './Card';
import Loader from '../components/Loader'
import axios from 'axios'
import PostItem from './PostItem'
import { useTranslation } from 'react-i18next';


const MainComponent = () => {
  const { t } = useTranslation();

  const scrollRef = useRef(null);

  //  to fetch posts
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/destinations`)
        setPosts(response?.data)
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
    fetchPosts();
  }, [])

  if (isLoading) {
    return <Loader/>
  }



  const scrollCards = (direction) => {
    const scrollAmount = 300; // Adjust as needed
    const container = scrollRef.current;
    if (container) {
      if (direction === 'prev') {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    }
  };

  return (
    <div className="main-container">
      <h3 className='maincompHeading'>{t('topdestination')}</h3>
      <button className="prev" onClick={() => scrollCards('prev')}>&#10094;</button>
      <div className="cards-main-container" ref={scrollRef}>
      {
            posts.map(({ _id: id, thumbnail, category, address, title, description, creator, createdAt }) =>
              <Card key={id} postID={id} thumbnail={thumbnail} category={category} title={title} description={description} authorID={creator} createdAt={createdAt} address={address}/>)
          }
 
      </div>
      <button className="next" onClick={() => scrollCards('next')}>&#10095;</button>
    </div>
  );
};

export default MainComponent;
