// src/utility/utils.js
import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true; // Consider token expired if there's an error decoding it
  }
};
