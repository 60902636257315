import React, { useEffect, useRef, useState } from 'react';

const Map = ({ lat, lng, name }) => {
  const mapRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const destination = { lat, lng }; // Destination coordinates

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const currentPos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentPosition(currentPos); // Set the current position state
      },
      (error) => {
        console.error("Error fetching current position:", error);
        handleGeolocationError(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  const handleGeolocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Location permission denied. Using fallback location.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable. Using fallback location.");
        break;
      case error.TIMEOUT:
        alert("Location request timed out. Using fallback location.");
        break;
      default:
        alert("An unknown error occurred while fetching location. Using fallback location.");
    }
    const fallbackPosition = { lat: 27.7172, lng: 85.3240 }; // Kathmandu, Nepal as fallback
    setCurrentPosition(fallbackPosition);
  };

  useEffect(() => {
    if (currentPosition && !mapInstance) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: currentPosition,
        zoom: 12,
        mapTypeControl: true,
        zoomControl: true,
        streetViewControl: true,
        fullscreenControl: true,
      });

      setMapInstance(map);

      const destinationMarker = new window.google.maps.Marker({
        position: destination,
        map: map,
        title: name,
        icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
      });

      // Add an info window with smaller text for the destination name
      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div style="font-size: 12px; font-weight: bold; padding: 5px;">${name}</div>`, // Custom styling
      });

      infoWindow.open(map, destinationMarker);

      new window.google.maps.Marker({
        position: currentPosition,
        map: map,
        title: 'Your Location',
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        },
      });

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRendererInstance = new window.google.maps.DirectionsRenderer({
        polylineOptions: {
          strokeColor: '#1E90FF',
          strokeOpacity: 0.7,
          strokeWeight: 5,
        },
        suppressMarkers: true,
      });

      directionsRendererInstance.setMap(map);
      setDirectionsRenderer(directionsRendererInstance);
    }
  }, [currentPosition, destination, mapInstance]);

  useEffect(() => {
    if (currentPosition && directionsRenderer) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: currentPosition,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error('Directions request failed due to ' + status);
            alert(`Unable to find a route to the destination. Status: ${status}`);
          }
        }
      );
    }
  }, [currentPosition, destination, directionsRenderer]);

  return (
    <div>
      <div ref={mapRef} style={{ width: '100%', height: '400px' }} /> {/* Map container */}
      {!currentPosition && <div>Loading map and your location...</div>} {/* Show while loading */}
    </div>
  );
};

export default Map;
