import 'react-quill/dist/quill.snow.css'
import { UserContext } from '../context/userContext'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react'
import ReactQuill from 'react-quill';


const CreatePost = () => {
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('Uncategorized')
  const [description, setDescription] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;
  Quill.register('modules/imageResize', ImageResize);

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
   }
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const POST_CATEGORIES = ["Products", "Festivals", "Destination", "Business", "Contacts", "Statistics", "Vada-Patra", "Enquiry", "Vada-Patra"]

  const createPost = async (e) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('title', title)
    postData.set('category', category)
    postData.set('description', description)
    postData.set('thumbnail', thumbnail)

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/posts`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 201) {
        return navigate('/')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a thumbnail.")
      } else {
        setError(err.response.data.message);
      }
    }
   }

  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={createPost}>
          <input type='text' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
          <div className="container-quill">
            <div className="box"> <select name='category' value={category} onChange={e => setCategory(e.target.value)}>
            {
              POST_CATEGORIES.map(cat => <option key={cat}>{cat}</option>)
            }
          </select></div>
            <div className="box">
              <input type="file" onChange={e => setThumbnail(e.target.files[0])} accept='png, jpg, jpeg' />
            </div>
            <div className="box">
              <button type="submit" className='btn primary'>Publish Article</button>
            </div>
          </div>
         
          <div style={{ height: '100vh' }}> {/* Set height of parent container */}
            <ReactQuill modules={modules} formats={formats} value={description} onChange={setDescription} style={{ height: '100%' }} /> {/* Set height of ReactQuill */}
    </div>        </form>
      </div>
    </section>
  )
}

export default CreatePost;

