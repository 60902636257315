import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../context/userContext';
import Loader from '../../components/Loader';
import '../../styles/NagrikVadaPatra.css'; // Create a separate CSS file for enhanced styling
import { useTranslation } from 'react-i18next';

const CreateNote = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [images, setImages] = useState([]);
  const [concernedBranch, setConcernedBranch] = useState('');
  const [serviceCharges, setServiceCharges] = useState('');
  const [requiredDocuments, setRequiredDocuments] = useState(['']);
  const [supportingDocuments, setSupportingDocuments] = useState(['']);
  const [procedureForProviding, setProcedureForProviding] = useState(['']);
  const [timeTaken, setTimeTaken] = useState(['']);
  const [responsibleOfficer, setResponsibleOfficer] = useState(['']);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const createNote = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const postData = new FormData();
    postData.set('title', title);
    postData.set('concerned_branch', concernedBranch);
    postData.set('service_charges', serviceCharges);
    postData.set('required_documents', JSON.stringify(requiredDocuments));
    postData.set('supporting_documents', JSON.stringify(supportingDocuments));
    postData.set('procedure_for_providing', JSON.stringify(procedureForProviding));
    postData.set('time_taken', JSON.stringify(timeTaken));
    postData.set('responsible_officer', JSON.stringify(responsibleOfficer));

    images.forEach(image => postData.append('images', image));

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/notes`, postData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 201) {
        navigate('/create');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'An error occurred.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    setImages(Array.from(files));
  };

  const handleAddItem = (setter) => {
    setter(prevItems => [...prevItems, '']);
  };

  const handleRemoveItem = (setter, index) => {
    setter(prevItems => prevItems.filter((_, i) => i !== index));
  };

  const handleItemChange = (setter, index, value) => {
    setter(prevItems => prevItems.map((item, i) => i === index ? value : item));
  };

  return (
    <section className="create-post">
      <div className="container">
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-post__form" onSubmit={createNote}>
          <input
            type="text"
            placeholder={t('title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoFocus
            required
          />
          <input
            type="text"
            placeholder={t('branch')}
            value={concernedBranch}
            onChange={(e) => setConcernedBranch(e.target.value)}
          />
          <input
            type="text"
            placeholder={t('service_charges')}
            value={serviceCharges}
            onChange={(e) => setServiceCharges(e.target.value)}
          />

          {/* Required Documents */}
          <DynamicFieldList
            label={t('required_documents')}
            items={requiredDocuments}
            onAdd={() => handleAddItem(setRequiredDocuments)}
            onRemove={(index) => handleRemoveItem(setRequiredDocuments, index)}
            onItemChange={(index, value) => handleItemChange(setRequiredDocuments, index, value)}
            t={t}
          />

          {/* Supporting Documents */}
          <DynamicFieldList
            label={t('supporting_documents')}
            items={supportingDocuments}
            onAdd={() => handleAddItem(setSupportingDocuments)}
            onRemove={(index) => handleRemoveItem(setSupportingDocuments, index)}
            onItemChange={(index, value) => handleItemChange(setSupportingDocuments, index, value)}
            t={t}
          />

          {/* Procedure for Providing */}
          <DynamicFieldList
            label={t('procedures')}
            items={procedureForProviding}
            onAdd={() => handleAddItem(setProcedureForProviding)}
            onRemove={(index) => handleRemoveItem(setProcedureForProviding, index)}
            onItemChange={(index, value) => handleItemChange(setProcedureForProviding, index, value)}
            t={t}
          />

          {/* Time Taken */}
          <DynamicFieldList
            label={t('time_taken')}
            items={timeTaken}
            onAdd={() => handleAddItem(setTimeTaken)}
            onRemove={(index) => handleRemoveItem(setTimeTaken, index)}
            onItemChange={(index, value) => handleItemChange(setTimeTaken, index, value)}
            t={t}
          />

          {/* Responsible Officer */}
          <DynamicFieldList
            label={t('responsible_officer')}
            items={responsibleOfficer}
            onAdd={() => handleAddItem(setResponsibleOfficer)}
            onRemove={(index) => handleRemoveItem(setResponsibleOfficer, index)}
            onItemChange={(index, value) => handleItemChange(setResponsibleOfficer, index, value)}
            t={t}
          />

          <div className="thumbnaildash2">
            <span>{t('choose_imagesfiles')}</span>
            <input type="file" onChange={handleImageChange} multiple />
          </div>
          <div className="boxdash">
            <button type="submit" className="btn primary submitbtn2">
              Publish Note
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

const DynamicFieldList = ({ label, items, onAdd, onRemove, onItemChange, t }) => (
  <div className="dynamic-field-list">
    <label>{label}:</label>
    {items.map((item, index) => (
      <div key={index} className="item-container">
        <input
          type="text"
          value={item}
          onChange={(e) => onItemChange(index, e.target.value)}
          placeholder={`${t('enter')} ${label.toLowerCase()}`}
        />
        <button type="button" className="btn remove-btn" onClick={() => onRemove(index)}>
          X
        </button>
      </div>
    ))}
    <button type="button" className="btn add-btn" onClick={onAdd}>
    + {label}
    </button>
  </div>
);

export default CreateNote;
