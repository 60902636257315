import React, { useEffect, useState } from 'react';
import { getGraphs } from '../api/graphs';
import Graphs from './Graphs';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WcIcon from '@mui/icons-material/Wc';

// Additional icons
import SchoolIcon from '@mui/icons-material/School';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupsIcon from '@mui/icons-material/Groups';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Suitable icon for boundaries

import HomeTabIcon from '@mui/icons-material/Home'; // Renamed import for the tab icon

import '../../styles/UserView.css';
import Mapkmz from '../../components/MapKmz';

// Update icon mapping to use the renamed home icon
const iconMapping = {
  Housing: <HomeTabIcon />,
  'Population size and distribution': <PeopleIcon />,
  Education: <SchoolIcon />,
  Fertility: <ChildFriendlyIcon />,
  'Economic Activity': <BusinessCenterIcon />,
  'Caste/Ethnicity': <GroupsIcon />,
  Uncategorized: <HomeTabIcon />,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UserView() {
  const [value, setValue] = useState(0);
  const [graphs, setGraphs] = useState([]);
  const [showTabs, setShowTabs] = useState(false);

  useEffect(() => {
    const fetchGraphs = async () => {
      const response = await getGraphs();
      setGraphs(response.data);
    };
    fetchGraphs();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const triggerHeight = 200;
      setShowTabs(scrollTop > triggerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const groupedGraphs = graphs.reduce((acc, graph) => {
    const profileName = graph.profile ? graph.profile.name : 'Uncategorized';
    (acc[profileName] = acc[profileName] || []).push(graph);
    return acc;
  }, {});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#ebeff7', padding: '20px' }} className='user-view'>
      {/* Boxes Section */}
      <Box id="boxes-section" sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '40px' }}>
        {/* Row of four small boxes */}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap', // Allows items to wrap to the next line if needed
            justifyContent: 'space-between',
            '@media (max-width: 900px)': {
              flexDirection: 'column', // Stack boxes on smaller screens
            },
          }}
        >
          {/* Box 1: Total Household */}
          <Box
            sx={{
              flex: '1 1 calc(25% - 20px)', // Each box takes up 25% of the row on larger screens
              minWidth: '200px',
              height: '150px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '@media (max-width: 900px)': {
                flex: '1 1 100%', // Full width on smaller screens
              },
            }}
          >
            <HomeIcon sx={{ fontSize: 40, color: '#e91e63', backgroundColor: '#ffebee', borderRadius: '50%', padding: '5px' }} />
            <Box>
              <Typography variant="h6" sx={{ color: '#1fa194', fontWeight: 'bold' }}>Total Household</Typography>
              <Typography variant="h4" sx={{ color: '#1fa194', fontWeight: 'bold' }}>21,508</Typography>
            </Box>
          </Box>

          {/* Box 2: Total Population */}
          <Box
            sx={{
              flex: '1 1 calc(25% - 20px)',
              minWidth: '200px',
              height: '150px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '@media (max-width: 900px)': {
                flex: '1 1 100%',
              },
            }}
          >
            <PeopleIcon sx={{ fontSize: 40, color: '#ff9800', backgroundColor: '#ffe0b2', borderRadius: '50%', padding: '5px' }} />
            <Box>
              <Typography variant="h6" sx={{ color: '#1fa194', fontWeight: 'bold' }}>Total Population</Typography>
              <Typography variant="h4" sx={{ color: '#1fa194', fontWeight: 'bold' }}>112,404</Typography>
            </Box>
          </Box>

          {/* Box 3: Gender Breakdown */}
          <Box
            sx={{
              flex: '1 1 calc(25% - 20px)',
              minWidth: '200px',
              height: '150px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '@media (max-width: 900px)': {
                flex: '1 1 100%',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <MaleIcon sx={{ fontSize: 25, color: '#3f51b5', backgroundColor: '#e3f2fd', borderRadius: '50%', padding: '5px' }} />
              <Typography variant="body1" sx={{ color: '#1fa194', fontWeight: 'bold' }}>57,998</Typography>
              <Typography variant="caption" sx={{ color: '#1fa194', fontWeight: 'bold' }}>Male</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <FemaleIcon sx={{ fontSize: 25, color: '#ff5722', backgroundColor: '#fce4ec', borderRadius: '50%', padding: '5px' }} />
              <Typography variant="body1" sx={{ color: '#1fa194', fontWeight: 'bold' }}>55,894</Typography>
              <Typography variant="caption" sx={{ color: '#1fa194', fontWeight: 'bold' }}>Female</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <WcIcon sx={{ fontSize: 25, color: '#009688', backgroundColor: '#e0f2f1', borderRadius: '50%', padding: '5px' }} />
              <Typography variant="body1" sx={{ color: '#1fa194', fontWeight: 'bold' }}>67</Typography>
              <Typography variant="caption" sx={{ color: '#1fa194', fontWeight: 'bold' }}>Other</Typography>
            </Box>
          </Box>

          {/* Box 4: Average Family Size */}
          <Box
            sx={{
              flex: '1 1 calc(25% - 20px)',
              minWidth: '200px',
              height: '150px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '@media (max-width: 900px)': {
                flex: '1 1 100%',
              },
            }}
          >
            <FamilyRestroomIcon sx={{ fontSize: 40, color: '#ff5722', backgroundColor: '#ffe0b2', borderRadius: '50%', padding: '5px' }} />
            <Box>
              <Typography variant="h6" sx={{ color: '#1fa194', fontWeight: 'bold' }}>Average Family Size</Typography>
              <Typography variant="h4" sx={{ color: '#1fa194', fontWeight: 'bold' }}>5.32</Typography>
            </Box>
          </Box>
        </Box>

        {/* Row of two large boxes */}
        <Box sx={{ display: 'flex', gap: '20px', marginTop: '20px', flexWrap: 'wrap' }}>
          {/* Large Box 1: Map */}
          <Box
            sx={{
              flex: '1 1 100%',
              height: '500px', // Increased height for better map view
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '@media (min-width: 600px)': {
                flex: '1 1 calc(50% - 20px)',
              },
            }}
          >
            <MapIcon sx={{ fontSize: 40, color: '#ff5722', backgroundColor: '#ffe0b2', borderRadius: '50%', padding: '5px' }} />
            <Typography variant="h6" sx={{ marginBottom: '10px', color: '#000', fontWeight: 'bold' }}>Rural Municipality Map</Typography>
            <div style={{ width: '100%', height: '100%' }} className='map'>
              <Mapkmz/>
            </div>
          </Box>

          {/* Large Box 2: Boundaries */}
          <Box
            sx={{
              flex: '1 1 100%',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '@media (min-width: 600px)': {
                flex: '1 1 calc(50% - 20px)',
              },
              position: 'relative',
            }}
          >
            {/* Icon and Title */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnIcon sx={{ fontSize: 40, color: '#1fa194', backgroundColor: '#e0f2f1', borderRadius: '50%', padding: '5px' }} />
              <Typography variant="h6" sx={{ marginLeft: '10px', color: '#000', fontWeight: 'bold' }}>Boundaries</Typography>
            </Box>
            {/* Boundary Information with Wider Line Spacing */}
            <Box sx={{ marginTop: '10px', marginBottom: '30px', lineHeight: '1.8' }}>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                East: <span style={{ color: '#1fa194', marginLeft: '10px' }}>Bedkot Municipality</span>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                North: <span style={{ color: '#1fa194', marginLeft: '10px' }}>Parshuram Municipality, Dadeldhura</span>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                West: <span style={{ color: '#1fa194', marginLeft: '10px' }}>Mahakali river</span>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                South: <span style={{ color: '#1fa194', marginLeft: '10px' }}>Shukaphanta National Park</span>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                Area: <span style={{ color: '#1fa194', marginLeft: '10px' }}>171.32 sq.km</span>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                Ward: <span style={{ color: '#1fa194', marginLeft: '10px' }}>19</span>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                Latitude and longitude: <span style={{ color: '#1fa194', marginLeft: '10px' }}>37.09N, 95.7127S</span>
              </Typography>
            </Box>
            {/* Learn More Button Adjusted for Responsiveness */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                variant="contained"
                href="#"
                sx={{
                  backgroundColor: '#fbc02d', // Dark yellow color
                  color: '#fff', // White text
                  '&:hover': {
                    backgroundColor: '#fff', // White background on hover
                    color: '#fbc02d', // Dark yellow text on hover
                  },
                }}
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Horizontal Scrollable Tab Bar */}
      {showTabs && (
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="profile tabs"
          variant="scrollable"
          scrollButtons="auto"
          className='sticky-tabs'
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#fff9c4',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
            '& .Mui-selected': {
              color: '#1976d2', // Highlight color for the selected tab
              borderBottom: '2px solid #1976d2', // Underline for the selected tab
            },
          }}
        >
          {Object.keys(groupedGraphs).map((profile, index) => (
            <Tooltip title={profile} arrow key={index}>
              <Tab 
                label={profile}
                {...a11yProps(index)} 
                icon={iconMapping[profile] || <HomeTabIcon />} 
                iconPosition="start"
                sx={{
                  textTransform: 'none', 
                  minWidth: 80,        
                  color: value === index ? '#1976d2' : '#000',
                  alignItems: 'center',
                  gap: 1,
                  "&:hover": {
                    backgroundColor: 'transparent', 
                  }
                }} 
              />
            </Tooltip>
          ))}
        </Tabs>
      )}

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        {Object.keys(groupedGraphs).map((profile, index) => (
          <CustomTabPanel value={value} index={index} key={index}>
            <div className="">
              <div className="graph-container">
                {groupedGraphs[profile].filter(graph => graph.type === 'raw').map((graph) => (
                  <div key={graph._id} >
                                      {/* <div key={graph._id} className="graph-item"> */}

                    <Graphs graph={graph} />
                  </div>
                ))}
              </div>
              <div className="graph-container pie-container">
                {groupedGraphs[profile].filter(graph => graph.type === 'pie').map((graph) => (
                  <div key={graph._id} >
                    {/* <div key={graph._id} className="graph-item"> */}
                    <Graphs graph={graph} />
                  </div>
                ))}
              </div>
              <div className="graph-container chart-container">
                {groupedGraphs[profile].filter(graph => graph.type !== 'raw' && graph.type !== 'pie').map((graph) => (
                  <div key={graph._id} >
                                      {/* <div key={graph._id} className="graph-item"> */}
                    <Graphs graph={graph} />
                  </div>
                ))}
              </div>
            </div>
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
}
