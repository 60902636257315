import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../images/logo3.png'
import { FaBars } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"

import { UserContext } from '../context/userContext'
import LanguageSelector from './Language'
import { AnchorTemporaryDrawer } from './Sidebar'
import { isTokenExpired } from '../utility/utils'; // Import the utility function



const Header = () => {
  const [isNavShowing, setIsNavShowing] = useState(window.innerWidth > 800 ? true : false);
  // const { currentUser } = useContext(UserContext)
  const { currentUser, logout } = useContext(UserContext);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // to logout if user token expires
  useEffect(() => {
    const checkToken = () => {
      const token = currentUser?.token;
      if (token && isTokenExpired(token)) {
        logout();
      }
    };

    checkToken(); // Check token expiration on component mount

    const intervalId = setInterval(checkToken, 1000); // Check token expiration every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentUser, logout, navigate]);



  const closeNavHandler = () => {
    if (window.innerWidth < 800) {
      setIsNavShowing(false)
    } else {
      setIsNavShowing(true)
    }
  }

  const handleFlagClick = (flag) => {
    console.log(`Flag ${flag} clicked`);
    // Add your logic here
  };

  return (
    // <nav className={scrolled ? 'navbar scrolled' : 'navbar'}>
    <nav className={scrolled ? 'navbar scrolled' : 'navbar'}>
      <div className="container nav__container" >
        <Link to="/" className='nav__logo' onClick={closeNavHandler}>
          <img src={Logo} alt="Navbar Logo" className='imageClassLogo'/>
        </Link>
       
        {/* {currentUser?.id && <ul className='nav__menu'>
          <li><Link to={`/profile/${currentUser.id}`} onClick={closeNavHandler}>{currentUser?.name}</Link></li>
          <Link to={`/myposts/${currentUser.id}`} onClick={closeNavHandler}>My Posts</Link>
          <li><Link to="/create" onClick={closeNavHandler}>Create Post</Link></li>
          <li><Link to="/authors" onClick={closeNavHandler}>Authors</Link></li>
          <li><Link to="/logout" onClick={closeNavHandler}>Logout</Link></li>
          <li><AnchorTemporaryDrawer /></li>
        </ul>} */}
       <ul className='nav__menu'>
          {/* <li><Link to="/authors" onClick={closeNavHandler}>Authors</Link></li> */}
          <li><LanguageSelector /></li>
          {currentUser && !isTokenExpired(currentUser.token) ? <li><Link to="/logout" onClick={closeNavHandler}>Logout</Link></li> : <li><Link to="/login" onClick={closeNavHandler}>Login</Link></li>}
          
          <li><AnchorTemporaryDrawer /></li>

        </ul>
        {/* <button className='nav__toggle-btn' onClick={() => setIsNavShowing(!isNavShowing)}>
          {isNavShowing ? <AiOutlineClose/> : <FaBars/>}
        </button> */}
      </div>
    </nav>
  )
}

export default Header
 