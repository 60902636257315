import React from 'react'
import { Link } from 'react-router-dom'

const CardImage = ({ postID, category, title, description, authorID, thumbnail, createdAt }) => {
    const shortDescription = description?.length > 145 ? description?.substr(0, 145) + '...' : description;
    const postTitle = title.length > 30 ? title.substr(0, 30) + '...' : title;
  return (
      <Link to={`/festivals/${postID}`}>
               <div className="carousel-image" key={postID}>
          <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt={title} className='imageClass' />
          <div className="card-crousel">
                  <h3>{ postTitle}</h3>
            <button>Details</button>
          </div>
              </div>
      </Link>
  )
}

export default CardImage
