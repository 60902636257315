import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import '../../styles/dashboard.css';

const ViewComplaint = () => {
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [complaint, setComplaint] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  const popoverRef = useRef(null);

  // Redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, []);

  // Fetch complaint data
  useEffect(() => {
    const getComplaint = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/complaints/${id}`);
        setTo(response.data.to);
        setFrom(response.data.from);
        setSubject(response.data.subject);
        setComplaint(response.data.complaint);
        setImages(response.data.images);
      } catch (error) {
        console.log(error);
        setError('Failed to fetch complaint.');
      }
    };
    getComplaint();
  }, [id]);

  // Handle click on thumbnail to view in full size
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // Handle close popover
  const handleClosePopover = () => {
    setSelectedImage(null);
  };

// Handle download image
const handleDownload = async (image) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = image;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};
  // Close popover when clicking outside of image
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setSelectedImage(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <section className="create-post">
      <div className="container">
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-post__form">
          <input type="text" placeholder="To" value={to} autoFocus readOnly />
          <input type="text" placeholder="From" value={from} autoFocus readOnly />
          <input type="text" placeholder="Subject" value={subject} autoFocus readOnly />
          <textarea type="text" placeholder="Complaint" value={complaint} autoFocus className="expandable-textarea" readOnly />
          <div className="thumbnail-container">
            {images.map((image, index) => (
              <div key={index} className="thumbnail-wrapper">
                <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} alt={image} className="thumbnail" onClick={() => handleImageClick(image)} />
              </div>
            ))}
          </div>
        </form>
        {selectedImage && (
          <div className="popover-overlay">
            <div className="popover" ref={popoverRef}>
              <span className="close" onClick={handleClosePopover}>&times;</span>
              <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${selectedImage}`} alt={selectedImage} className="popover-image" />
              <div className="popover-actions">
                <button onClick={() => handleDownload(selectedImage)}>Download</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ViewComplaint;
