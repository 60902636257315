import React from 'react';
import HomePage from '../images/homepage5.jpg';
import '../styles/banner.css';
import { Link } from 'react-router-dom';
import { FaAddressBook, FaBeer, FaBox, FaBriefcase, FaCalculator, FaCalendarAlt, FaChartBar, FaFrown, FaMap, FaStickyNote } from 'react-icons/fa';
import Iconimage from '../images/homepage2.jpg';
import Sideimage from '../images/avatar1.jpg';
import GridImage from './GridImage';
import Card from './Card';
import MainComponent from './MainCompoents';
import MidBanner from './MidBanner';
import Map from './Map';
import { useTranslation } from 'react-i18next';
import YouTubeVideo from './YoutubeVideo';


const Banner = () => {
  const { t } = useTranslation();


  return (
      <>
      {/* 1------banner head(image) */}
          <div className="home-page">
      <img src={HomePage} alt="Homepage" className='imageClass' />
      <div className="overlay-container">
          <h1 className='banner_heading'>{t('welcom')}</h1>
          <h3 className='banner_subheading'>{t('banner_subheading')}</h3>
        {/* <h4 className='banner_minheading'>Where Every Experience, Product, and Destination is at Your Fingertips, Backed by Robust Features and Seamless Connectivity</h4> */}
        <div className="white-bar">
          <div className="grid-bar">
            <div className="grid-item">
              <Link to="/contacts">
              <div className="icon-container"><FaAddressBook /></div> 
                  <span>{t('contact')}</span>
              </Link>
            </div>
            <div className="grid-item">
              <Link to="digitalprofile">
              <div className="icon-container"><FaChartBar/></div>
                  <span>{t('stat')}</span>
              </Link>
            </div>
            <div className="grid-item">
              <Link to="/festivals">
              <div className="icon-container"><FaCalendarAlt/></div>
                  <span>{t('festival')}</span>
              </Link>
              </div>
              <div className="grid-item">
              <Link to="/destinations">
              <div className="icon-container"><FaMap/></div>
                  <span>{t('destination')}</span>
              </Link>
            </div>
            <div className="grid-item">
              <Link to="/products">
              <div className="icon-container"><FaBox/></div>
                  <span>{t('product')}</span>
              </Link>
            </div>
            <div className="grid-item">
              <Link to="/businesses">
              <div className="icon-container"><FaBriefcase/></div>
                  <span>{t('business')}</span>
              </Link>
                      </div>
                      <div className="grid-item">
              <Link to="/notes">
              <div className="icon-container"><FaStickyNote/></div>
                  <span>{t('note')}</span>
              </Link>
                      </div>
                        <div className="grid-item">
              <Link to="/complaints">
              <div className="icon-container"><FaFrown/></div>
                  <span>{t('complaint')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
          </div>
          {/* 2------some text */}
          <div className="two-division-block container">
      <div className="left-division">
        <div className="icon-text">
          {/* <img src={Iconimage} alt="Icon" className="icon-img" /> */}
            <h2>{t('discover')}</h2>
        </div>
        <div className="vertical-bar"></div>
      </div>
      <div className="right-division">
        <div className="content-wrapper">
            <h4>{t('village_municipality')}</h4>
            <p>{t('aboutDetail')}</p>
        </div>
      </div>
          </div>

          
          <MainComponent />
          <MidBanner />
          
          
          {/* youtube video */}
      {/* <div className='youtube-video'>
        <iframe
          width="100%"
          height="500"
          className='youtube-iframe'
          src={`https://www.youtube.com/embed/BTubTzMb5VA?si=eaxSAv0_CDj0z-Ao`}
          title="Welcome to Lekam"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div> */}
      <YouTubeVideo/>

      <div className="horizontal-bar-style">
  <div className="inner-bar"></div>
</div>

      
      </>
  );
};

export default Banner;