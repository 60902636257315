import { UserContext } from '../../context/userContext'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import QuillEditor from '../../components/QuillEditor'
import Loader from '../../components/Loader'

const CreatePopup = () => {
  const [thumbnail, setThumbnail] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;
  // Quill.register('modules/imageResize', ImageResize);


  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])


  const createPopup = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const postData = new FormData();
    postData.set('thumbnail', thumbnail)

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/popups`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 201) {
        return navigate('/create')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a image.")
      } else {
        setError(err.response.data.message);
      }
    }
    setIsLoading(false);
  }
  
  if (isLoading) {
    return <Loader/>
  }


  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={createPopup}>
          <div className="thumbnaildash">
          <span>Choose thumbnail : </span>
              <input type="file" onChange={e => setThumbnail(e.target.files[0])} accept='png, jpg, jpeg' />
            </div>
            
            
          <div className="boxdash">
              <button type="submit" className='btn primary submitbtn'>Publish Popup</button>
            </div>
        </form>
      </div>
    </section>
  )
}

export default CreatePopup;

