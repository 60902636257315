import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ButtonComponent = ({ onButtonClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedButton, setSelectedButton] = useState("Products");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (buttonName) => {
        setAnchorEl(null);
        if (buttonName) {
            setSelectedButton(buttonName);
            onButtonClick(buttonName);
            console.log(buttonName, "pressed");
        }
    };

    return (
        <div className='dashSearchbtn' style={{ textAlign: 'center' }}>
            <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
            >   
                {selectedButton === "notes" 
                ? "vada-patra" 
                : selectedButton === "complaints" 
                ? "enquiry"
                : selectedButton === "stats"
                ? "digital profile"
                : selectedButton}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose('products')}>Products</MenuItem>
                <MenuItem onClick={() => handleClose('festivals')}>Festivals</MenuItem>
                <MenuItem onClick={() => handleClose('destinations')}>Destinations</MenuItem>
                <MenuItem onClick={() => handleClose('businesses')}>Business</MenuItem>
                <MenuItem onClick={() => handleClose('contacts')}>Contacts</MenuItem>
                <MenuItem onClick={() => handleClose('complaints')}>Enquiry</MenuItem>
                <MenuItem onClick={() => handleClose('stats')}>Statistics</MenuItem>
                <MenuItem onClick={() => handleClose('notes')}>Vada-Patra</MenuItem>
            </Menu>
        </div>
    );
};

export default ButtonComponent;
