import React, { useEffect } from 'react';

function Mapkmz() {
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      // Avoid adding the script multiple times
      if (!document.querySelector('#google-maps-script')) {
        const script = document.createElement('script');
        script.id = 'google-maps-script';
        // Add `loading=async` to the URL for optimal performance
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCYrsWaiLthzrfGKTzb9xil7sEhqGwaZwE&callback=initMap&loading=async`;
        script.async = true;  // Ensure script loads asynchronously
        script.defer = true;  // Ensure script execution is deferred
        document.body.appendChild(script);
      }

      // Initialize the map once the script is loaded
      window.initMap = function () {
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: -19.257753, lng: 146.823688 },
          zoom: 2,
          mapTypeId: 'terrain',
        });

        const kmlLayer = new window.google.maps.KmlLayer(
          'https://lekamkmz.s3.eu-north-1.amazonaws.com/Lekam+Rural+Municipality-Darchula+(1).kmz',
          {
            suppressInfoWindows: true,
            preserveViewport: false,
            map: map,
          }
        );

        kmlLayer.addListener('click', function (event) {
          const content = event.featureData.infoWindowHtml;
          const captureElement = document.getElementById('capture');
          if (captureElement) {
            captureElement.innerHTML = content;
          }
        });
      };
    };

    loadGoogleMapsScript(); // Load the script when the component is mounted
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '400px', width: '100%' }} className='map'>
      <div
        id="map"
        style={{
          flex: 1,
          overflow: 'hidden',
          border: 'none',
        }}
      ></div>
      {/* Optional capture element */}
      {/* <div
        id="capture"
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: '#ECECFB',
          border: 'thin solid #333',
          padding: '10px',
          boxSizing: 'border-box',
        }}
      ></div> */}
    </div>
  );
}

export default Mapkmz;
