import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import Layout from './components/Layout';
import ErrorPage from './pages/ErrorPage'
import Home from './pages/Home'
import PostDetail from './pages/PostDetail'
import Register from './pages/Register'
import Login from './pages/Login'
import UserProfile from './dashboard/UserProfile'
import Authors from './dashboard/Authors'
import CreatePost from './pages/CreatePost'
import CategoryPosts from './pages/CategoryPosts'
import AuthorPosts from './dashboard/AuthorPosts'
import EditPost from './pages/EditPost'
import DeletePost from './pages/DeletePost'
import Logout from './pages/Logout'
import UserProvider from './context/userContext';
import CreateButtons from './dashboard/CreateButtons';
import DashBoardMain from './dashboard/DashBoardMain';
import CreateProduct from './dashboard/products/CreateProduct';
import CreateFestival from './dashboard/festivals/CreateFestival';
import CreateDestination from './dashboard/destinations/CreateDestination';
import CreateNote from './dashboard/notes/CreateNote';
import CreateComplaint from './dashboard/enquiries/CreateComplaint';
import CreateBusiness from './dashboard/businesses/CreateBusiness';
import CreateContact from './dashboard/contacts/CreateContact';
import Dashboard from './dashboard/Dashboard';
import EditProduct from './dashboard/products/EditProduct';
import EditFestival from './dashboard/festivals/EditFestival';
import EditDestination from './dashboard/destinations/EditDestination';
import EditBusiness from './dashboard/businesses/EditBusiness';
import EditContact from './dashboard/contacts/EditContact';
import EditComplaint from './dashboard/enquiries/EditComplaint';
import CreateStat from './dashboard/statistics/CreateStat';
import EditStat from './dashboard/statistics/EditStat';
import EditNote from './dashboard/notes/EditNote';
import Festivals from './pages/Festivals';
import Products from './pages/Products';
import Destinations from './pages/Destinations';
import Complaints from './pages/Complaints';
import Businesses from './pages/Businesses';
import Contacts from './pages/Contacts';
import Statistics from './pages/Statistics';
import Notes from './pages/Notes';
import BusinessDetail from './pages/BusinessDetail';
import NoteDetail from './pages/NoteDetail';
import ViewComplaint from './dashboard/enquiries/ViewComplaint';
import './i18n';
import ProductDetail from './pages/ProductDetails';
import Charts from './digitalProfile/Charts';
import DashboardProfile from './digitalProfile/components/DashboardProfile';
import UserView from './digitalProfile/components/UserView';
import DestinationDetail from './pages/DestinationDetail';

// for pwa
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import CreatePopup from './dashboard/popup/createPopup';

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserProvider><Layout /></UserProvider>,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "/ ", element: <Home /> },
      { path: "businesses/:id", element: <BusinessDetail /> },
      { path: "notes/:id", element: <NoteDetail /> },
      { path: "destinations/:id", element: <DestinationDetail /> },
      { path: "products/:id", element: <ProductDetail /> },
      { path: "complaints/:id", element:<ViewComplaint/>},
      { path: ":moduleName/:id", element: <PostDetail /> },
      { path: "register", element: <Register /> },
      { path: "login", element: <Login /> },
      { path: "profile/:id", element: <UserProfile /> },
      { path: "authors", element: <Authors /> },
      // { path: "create", element: <CreatePost /> },
      { path: "create", element: <CreateButtons /> },
      // { path: "create", element: <DashBoardMain /> },
      { path: "posts/categories/:category", element: <CategoryPosts /> },
      { path: "posts/users/:id", element: <AuthorPosts /> },
      { path: "myposts", element: <Dashboard /> },
      { path: "posts/:id/edit", element: <EditPost /> },
      { path: "posts/:id/delete", element: <DeletePost /> },
      { path: "logout", element: <Logout /> },
      // dashboard
      // products
      { path: "products", element: <Products /> },
      { path: "product", element: <CreateProduct /> },
      { path: "products/:id/edit", element: <EditProduct /> },
      // festivals
      { path: "festivals", element: <Festivals /> },
      { path: "festival", element: <CreateFestival /> },
      { path: "festivals/:id/edit", element: <EditFestival /> },
      // destinations
      { path: "destinations", element: <Destinations /> },
      { path: "destination", element: <CreateDestination /> },
      { path: "destinations/:id/edit", element: <EditDestination /> },
      // complaints (enquiry)
      { path: "complaints", element: <Complaints /> },
      { path: "complain", element: <CreateComplaint /> },
      { path: "complaints/:id/edit", element: <EditComplaint /> },
      // bsuinesses
      { path: "businesses", element: <Businesses /> },
      { path: "business", element: <CreateBusiness /> },
      { path: "businesses/:id/edit", element: <EditBusiness /> },
      // contacts
      { path: "contacts", element: <Contacts /> },
      { path: "contact", element: <CreateContact /> },
      { path: "contacts/:id/edit", element: <EditContact /> },
      // stats
      { path: "statistics", element: <Statistics /> },
      { path: "stats", element: <CreateStat /> },
      { path: "stats/:id/edit", element: <EditStat /> },
      // notes (vada-patra)
      { path: "notes", element: <Notes /> },
      { path: "note", element: <CreateNote /> },
      { path: "notes/:id/edit", element: <EditNote /> },
      // digital profile
      { path: "charts", element: <Charts /> },
      { path: "/graph", element: <DashboardProfile /> },
      { path: "/digitalprofile", element: <UserView /> },
      // for popup
      { path: "popup", element: <CreatePopup /> },
    ]
  }
])
   
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Register the service worker
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (window.confirm('New version available. Do you want to update?')) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});