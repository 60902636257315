// Notes component as before

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TablePagination, FormControl, Select, MenuItem, InputLabel, Typography, TextField, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NepalGovtLogo from '../images/nepal-government.png'; // Adjust the import path as needed
import Row from '../utility/Row';
import { useTranslation } from 'react-i18next';

const headerRed = '#ff0000'; // Red color for the header
const bodyBlue = '#007bff'; // Blue color for the rest of the table
const whiteColor = '#ffffff'; // White color for text
const blueColor = '#007bff'; // Blue color for the title text

const Notes = () => {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set default to 10 rows per page
    const [isCollapsible, setIsCollapsible] = useState(window.innerWidth < 1600);
    const [isPhoneView, setIsPhoneView] = useState(window.innerWidth < 600);

    const { category } = useParams();

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}/notes`)
            .then(response => {
                setPosts(response.data);
                setFilteredPosts(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log('Error fetching notes:', error);
                setIsLoading(false);
            });
    }, [category]);

    useEffect(() => {
        const handleResize = () => {
            setIsCollapsible(window.innerWidth < 1600);
            setIsPhoneView(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const results = posts.filter(post =>
            (post.no ? post.no.toString().includes(searchQuery) : false) ||
            (post.title ? post.title.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
            (post.concerned_branch ? post.concerned_branch.toLowerCase().includes(searchQuery.toLowerCase()) : false)
        );
        setFilteredPosts(results);
        setPage(0);  // Reset to first page when filtering
    }, [searchQuery, posts]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="nagrik_container">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                sx={{
                    marginBottom: '20px',
                    maxWidth: '100%', // Adjust to full width for centering
                    margin: '0 auto', // Center the title
                    textAlign: 'center', // Center the text content
                    marginBottom: { xs: '5%', sm: 0 }
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
        component="img"
        src={NepalGovtLogo}
        alt="Nepal Government Logo"
        sx={{
          height: { xs: '30px', sm: '40px', md: '50px' }, // Smaller height for mobile, larger for bigger screens
          marginRight: '8px',
        }}
      />
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: blueColor, fontSize: {
          xs: '1.2rem', // Small screen
          sm: '2rem',   // Medium screen
          md: '2rem', // Large screen
          lg: '2rem',   // Extra large screen
        }, }}>
                        {t('citizen_charter')}
                    </Typography>
                     <Box
                     component="img"
                     src={NepalGovtLogo}
                     alt="Nepal Government Logo"
                     sx={{
                       height: { xs: '30px', sm: '40px', md: '50px' }, // Smaller height for mobile, larger for bigger screens
                       marginLeft: '8px',
                     }}
                   />
                </Box>
                {/* <Typography variant="subtitle1" component="h6" sx={{ color: blueColor, fontWeight: 'bold', marginTop: '-2px' }}>
                    Nagrik Vada Patra
                </Typography> */}
            </Box>

            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextField
                    placeholder='Search'
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ minWidth: 200, height: '36px' }}  // Adjust height here
                    InputProps={{
                        sx: {
                            height: '36px',  // Adjust input height
                            paddingTop: 0,
                            paddingBottom: 0,
                            display: 'flex',
                            alignItems: 'center',  // Center placeholder text vertically
                            justifyContent: 'center', // Center placeholder horizontally
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120, height: '36px', display: 'flex', justifyContent: 'center' }}>
                    <InputLabel id="rows-per-page-label" sx={{ lineHeight: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Entries</InputLabel>
                    <Select
                        labelId="rows-per-page-label"
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                        label="Entries"
                        sx={{ height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  // Adjust height and center text
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: 200
                                }
                            }
                        }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Paper sx={{ width: '100%', overflowX: isPhoneView ? 'hidden' : 'auto' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" sx={{ minWidth: isPhoneView ? '100%' : 650 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: headerRed, fontSize: isPhoneView ? '12px' : 'inherit' }}>
                                {/* {isCollapsible && <TableCell sx={{ border: `1px solid ${whiteColor}` }} />} */}
                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('no')}</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('title')}</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('branch')}</TableCell>
                                {!isPhoneView && (
                                    <>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('service_charges')}</TableCell>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('required_documents')}</TableCell>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('supporting_documents')}</TableCell>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('procedures')}</TableCell>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('time_taken')}</TableCell>
                                        {!isCollapsible && <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('responsible_officer')}</TableCell>}
                                        {!isCollapsible && <TableCell sx={{ color: 'white', fontWeight: 'bold', border: `1px solid ${whiteColor}` }}>{t('images')}</TableCell>}
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((post) => (
                                <Row
                                    key={post._id}
                                    row={post}
                                    isCollapsible={isCollapsible}
                                    isPhoneView={isPhoneView}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]} // Add 100 rows per page option
                    component="div"
                    count={filteredPosts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};

export default Notes;
