import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../context/userContext';
import Loader from '../components/Loader';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaPhone } from 'react-icons/fa';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const ProductDetail = () => {
  const { id, moduleName } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useContext(UserContext);

  // Fetch product details
  useEffect(() => {
    const getProduct = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/${id}`);
        setProduct(response?.data);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    getProduct();
  }, [id, moduleName]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="post-detail">
      {error && <p className='error'>{error.message}</p>}
      {product && (
        <div className="container post-detail__container">
          <h1 className='post_deatail-heading'>{product.title}</h1>
          <div className="post-detail__thumbnail">
            <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${product.thumbnail}`} alt={product.title} className='imageClass'/>
          </div>
          <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
          <h3 className='post_deatail-heading'>Contact and Seller</h3>
          {/* {product.nestedFields && product.nestedFields.map((field, index) => (
            <div key={index} className="product-detail__nested-item">
              <h3>Nested Item {index + 1}</h3>
              <p><strong>Phone 1:</strong> {field.phone1}</p>
              <p><strong>Phone 2:</strong> {field.phone2}</p>
              <p><strong>Name:</strong> {field.name}</p>
              <p><strong>Price:</strong> {field.price}</p>
              <p><strong>Unit:</strong> {field.unit}</p>
              <p><strong>Address:</strong> {field.address}</p>
            </div>
          ))} */}
          <div className='seller_table'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className='table_row'>
                  <TableCell  className='table_heading'>Phone 1</TableCell>
                  <TableCell align="right" className='table_heading'>Phone 2</TableCell>
                  <TableCell align="right" className='table_heading'>Name</TableCell>
                  <TableCell align="right" className='table_heading'>Price</TableCell>
                  <TableCell align="right" className='table_heading'>Unit</TableCell>
                  <TableCell align="right" className='table_heading'>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.nestedFields && product.nestedFields.map((field, index) => (
                  <TableRow
                    key={field.phone1}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                    <a href={`tel:${field.phone1}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className='icon-container-product'>
                            <FaPhone /> {/* Phone Icon */}
                        </div>{field.phone1}
                        </a>
                    </TableCell>
                    <TableCell align="right"> <a href={`tel:${field.phone1}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {field.phone2 && <div className='icon-container-product'>
                        <FaPhone /> {/* Phone Icon */}
                      </div>}{field.phone2}
                      </a>
                    </TableCell>
                    <TableCell align="right">{field.name}</TableCell>
                    <TableCell align="right">{field.price}</TableCell>
                    <TableCell align="right">{field.unit}</TableCell>
                    <TableCell align="right">{field.address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table> 
          </TableContainer>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProductDetail;
