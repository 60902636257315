import React from 'react';
import { Box, Container, Grid, Link, Typography, IconButton } from '@mui/material';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube, FaMailchimp, FaVoicemail, FaEnvelope, FaPhone } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ background: 'linear-gradient(135deg, #1fa194 40%, #2a5298 100%)', color: 'white', py: 6, marginTop: 10 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" textAlign={{ xs: 'center', md: 'left' }}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: "yellow" }}>
              {t("aboutus")}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Poppins, sans-serif' }}>
              {t("footer_desc")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: "yellow" }}>
              {t("quick_links")}
            </Typography>
            <Link href="/contacts" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }} >
              {t('contact')}
            </Link>
            <Link href="/statistics" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('stat')}
            </Link>
            <Link href="/festivals" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('festival')}
            </Link>
            <Link href="/destinations" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('destination')}
            </Link>
            <Link href="/products" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('product')}
            </Link>
            <Link href="/businesses" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('business')}
            </Link>
            <Link href="/notes" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('note')}
            </Link>
            <Link href="/complaints" color="inherit" variant="body2" underline="none" sx={{ display: 'block', mb: 1, fontFamily: 'Poppins, sans-serif', '&:hover': { color: '#f1c40f' } }}>
              {t('complaint')}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: "yellow" }}>
              {t("contact_us")}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Poppins, sans-serif' }}><FaEnvelope/> lekam.rmun@gmail.com</Typography>
            <Typography variant="body2" style={{ fontFamily: 'Poppins, sans-serif' }}><FaPhone /> {t("phone_no")}</Typography>
            <Typography variant="body2" style={{ fontFamily: 'Poppins, sans-serif' }}>{t("footer_address")}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: "yellow" }}>
              {t("follow_us")}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, gap: 2 }}>
              <IconButton component="a" href="https://facebook.com" sx={{ color: 'white', '&:hover': { color: '#f1c40f' } }}>
                <FaFacebook />
              </IconButton>
              <IconButton component="a" href="https://twitter.com" sx={{ color: 'white', '&:hover': { color: '#f1c40f' } }}>
                <FaTwitter />
              </IconButton>
              <IconButton component="a" href="https://instagram.com" sx={{ color: 'white', '&:hover': { color: '#f1c40f' } }}>
                <FaInstagram />
              </IconButton>
              <IconButton component="a" href="https://linkedin.com" sx={{ color: 'white', '&:hover': { color: '#f1c40f' } }}>
                <FaLinkedin />
              </IconButton>
              <IconButton component="a" href="https://youtube.com" sx={{ color: 'white', '&:hover': { color: '#f1c40f' } }}>
                <FaYoutube />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }} style={{ fontFamily: 'Poppins, sans-serif' }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Your Company. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
