import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import axios from 'axios'
import '../../styles/dashboard.css';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const EditStat = () => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])
  const [error, setError] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState([]);


  const navigate = useNavigate();
  const { id } = useParams();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[])

  // to fetch post data

  useEffect(() => {
    const getPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stats/${id}`)
        setTitle(response.data.title)
        setDescription(response.data.description)
        setImages(response.data.images)
      } catch (error) {
        console.log(error)
      }
    }
    getPost();
  }, [id])


  // for react quill
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]


  // to edit post
  const editStat = async (e ) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('title', title)
    postData.set('description', description)
    for (let i = 0; i < images.length; i++) {
        postData.append('images', images[i]);
    }
    console.log(postData.getAll("images"))
    try {
        const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/stats/${id}`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
        console.log("response.data")
        console.log("Images:", postData.getAll('images'));
        if (response.status == 200) {
        return navigate('/myposts')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'images'") {
        setError("Please choose a images yo.")
      } else {
        setError(err.response.data.message);
      }
    }
  }

  //  to add more images
  const handleImageChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    const combinedFiles = [...images, ...fileList];
    setImages(combinedFiles);

    const urls = fileList.map((file) => URL.createObjectURL(file));
    setThumbnailUrl(urls);
  };
  
  // to remove images
  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedThumbnails = [...thumbnailUrl];
    updatedThumbnails.splice(index, 1);
    setThumbnailUrl(updatedThumbnails);
};
    

  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={editStat}>
          <input type='text' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
            {/* Display thumbnail image if selected */}
  <div className="thumbnail-container">
                        <input type="file" onChange={handleImageChange} accept="image/*" multiple className="custom-file-input"/>
                        {images.map((image, index) => (
                          <div key={index} className="thumbnail-wrapper">
                            { image == "[object File]" ? <img src={URL.createObjectURL(image)} alt="" className="thumbnail" /> : <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} alt={image} className="thumbnail" />}
                            <button type="button" onClick={() => removeImage(index)} className="remove-button">X</button>
                            </div>
                        ))}
                    </div>
          <div style={{ height: '40vh' }}> {/* Set height of parent container */}
            <ReactQuill modules={modules} formats={formats} value={description} onChange={setDescription} style={{ height: '100%' }} /> {/* Set height of ReactQuill */}
          </div>

            <div className="boxdash">
              <button type="submit" className='btn primary submitbtn'>Update Stats</button>
            </div>
         
     </form>
      </div>
    </section>
  )
}

export default EditStat
