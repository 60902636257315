import React, { useState } from 'react';
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PDFModal from './PDFModal'; // Import the PDF modal
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

const Row = ({ row, isCollapsible, isPhoneView }) => {
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState('');

    const handleOpenModal = (url) => {
        setSelectedUrl(url);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedUrl('');
        setModalOpen(false);
    };

    const renderList = (items) => (
        <ol style={{ paddingLeft: '20px', margin: '10px 0', lineHeight: '1.6', listStyleType: 'decimal', color: '#ffffff' }}>
            {items.map((item, index) => (
                <li key={index} style={{ marginBottom: '8px', fontSize: '14px', fontWeight: 'bold' }}>
                    {item}
                </li>
            ))}
        </ol>
    );

    // const renderImages = (images) => (
    //     <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
    //         {images.map((image, index) => (
    //             image.endsWith('.pdf') ? (
    //                 <div
    //                     key={index}
    //                     onClick={() => handleOpenModal(`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`)}
    //                     style={{
    //                         width: '40px',
    //                         height: '40px',
    //                         backgroundColor: '#f0f0f0',
    //                         display: 'flex',
    //                         alignItems: 'center',
    //                         justifyContent: 'center',
    //                         cursor: 'pointer',
    //                         borderRadius: '4px',
    //                     }}
    //                 >
    //                     PDF
    //                 </div>
    //             ) : (
    //                 <img
    //                     key={index}
    //                     src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`}
    //                     alt={`Image ${index + 1}`}
    //                     style={{
    //                         width: '40px',
    //                         height: '40px',
    //                         objectFit: 'cover',
    //                         borderRadius: '4px',
    //                         cursor: 'pointer',
    //                         marginRight: index % 2 === 1 ? '0' : '10px',
    //                         marginBottom: '10px',
    //                     }}
    //                     onClick={() => handleOpenModal(`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`)}
    //                 />
    //             )
    //         ))}
    //     </div>
    // );


    const renderImages = (files) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {files.map((file, index) => {
                const fileExtension = file.split('.').pop().toLowerCase();
                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                const isPDF = fileExtension === 'pdf';

                if (isImage) {
                    return (
                        <img
                            key={index}
                            onClick={() => handleOpenModal(`${process.env.REACT_APP_ASSETS_URL}/uploads/${file}`)}
                            src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${file}`}
                            alt={`File ${index + 1}`}
                            style={{
                                width: '40px',
                                height: '40px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                                marginBottom: '10px',
                            }}
                        />
                    );
                } else if (isPDF) {
                    return (
                        <iframe
                            key={index}
                            onClick={() => handleOpenModal(`${process.env.REACT_APP_ASSETS_URL}/uploads/${file}`)}
                            src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${file}`}
                            type="application/pdf"
                            width="40px"
                            height="40px"
                            style={{
                                borderRadius: '4px',
                                marginBottom: '10px',
                            }}
                        />
                    );
                } else {
                    return (
                        <a
                            key={index}
                            href={`${process.env.REACT_APP_ASSETS_URL}/uploads/${file}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${file}`}
                                alt={`File ${index + 1}`}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    objectFit: 'cover',
                                    borderRadius: '4px',
                                    marginBottom: '10px',
                                }}
                            />
                        </a>
                    );
                }
            })}
        </div>
    );

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: `1px solid #ffffff`, bgcolor: '#007bff' }, fontSize: isPhoneView ? '12px' : 'inherit' }}>
                {/* {isCollapsible && (
                    <TableCell sx={{ border: `1px solid #ffffff` }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )} */}
                <TableCell component="th" scope="row" sx={{ border: `1px solid #ffffff`, color: '#ffffff', fontWeight: 'bold' }}>
                    {row.no}
                    {/* to expand row */}
                    {/* <TableCell sx={{ border: `1px solid #ffffff` }}> */}
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    {/* </TableCell> */}
                </TableCell>
                <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff', fontWeight: 'bold' }}>{row.title}</TableCell>
                <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff', fontWeight: 'bold' }}>{row.concerned_branch}</TableCell>
                {!isPhoneView && (
                    <>
                        <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff', fontWeight: 'bold' }}>{row.service_charges}</TableCell>
                        <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff' }}>{renderList(row.required_documents)}</TableCell>
                        <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff' }}>{renderList(row.supporting_documents)}</TableCell>
                        <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff' }}>{renderList(row.procedure_for_providing)}</TableCell>
                        <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff' }}>{renderList(row.time_taken)}</TableCell>
                        {!isCollapsible && <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff' }}>{renderList(row.responsible_officer)}</TableCell>}
                        {!isCollapsible && <TableCell sx={{ border: `1px solid #ffffff`, color: '#ffffff' }}>{renderImages(row.images)}</TableCell>}
                    </>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={isPhoneView ? 3 : 9} sx={{ border: `1px solid #ffffff`, bgcolor: '#007bff' }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {isPhoneView && (
                                <>
                                    <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                        <strong>Service Charges:</strong> {row.service_charges}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                        <strong>Required Documents:</strong> {renderList(row.required_documents)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                        <strong>Supporting Documents:</strong> {renderList(row.supporting_documents)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                        <strong>Procedures:</strong> {renderList(row.procedure_for_providing)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                        <strong>Time Taken:</strong> {renderList(row.time_taken)}
                                    </Typography>
                                </>
                            )}
                            <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                <strong>Responsible Officer:</strong> {renderList(row.responsible_officer)}
                            </Typography>
                            {/* <Typography variant="body2" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                <Link to={`/notes/${row._id}`}>
                                    <FaEye title="View Details" style={{ color: '#ffffff' }} />
                                </Link>
                            </Typography> */}
                            {/* {isPhoneView && !( */}
                                <Box sx={{ marginTop: '10px' }}>
                                    <Typography variant="body2" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                        Images:
                                    </Typography>
                                    {renderImages(row.images)}
                                </Box>
                            {/* )} */}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <PDFModal open={modalOpen} handleClose={handleCloseModal} url={selectedUrl} />
        </>
    );
};

export default Row;
