import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../context/userContext';
import Loader from '../../components/Loader';
import '../../styles/dashboard.css';
import { useTranslation } from 'react-i18next';

const EditNote = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [images, setImages] = useState([]);
  const [concernedBranch, setConcernedBranch] = useState('');
  const [serviceCharges, setServiceCharges] = useState('');
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [procedureForProviding, setProcedureForProviding] = useState([]);
  const [timeTaken, setTimeTaken] = useState([]);
  const [responsibleOfficer, setResponsibleOfficer] = useState([]);
  const [error, setError] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/notes/${id}`);
        const note = response.data;

        setTitle(note.title);
        setImages(note.images);
        setConcernedBranch(note.concerned_branch);
        setServiceCharges(note.service_charges);
        setRequiredDocuments(note.required_documents || []);
        setSupportingDocuments(note.supporting_documents || []);
        setProcedureForProviding(note.procedure_for_providing || []);
        setTimeTaken(note.time_taken || []);
        setResponsibleOfficer(note.responsible_officer || []);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    getPost();
  }, [id]);

  const editNote = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const postData = new FormData();
    postData.set('title', title);
    postData.set('concerned_branch', concernedBranch);
    postData.set('service_charges', serviceCharges);
    requiredDocuments.forEach(doc => postData.append('required_documents', doc));
    supportingDocuments.forEach(doc => postData.append('supporting_documents', doc));
    procedureForProviding.forEach(step => postData.append('procedure_for_providing', step));
    timeTaken.forEach(time => postData.append('time_taken', time));
    responsibleOfficer.forEach(officer => postData.append('responsible_officer', officer));

    images.forEach(image => {
      postData.append('images', image);
    });

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/notes/${id}`, postData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        navigate('/myposts');
      }
    } catch (err) {
      if (err.response?.data?.message.includes("Cannot read properties of null (reading 'images')")) {
        setError("Please choose an image.");
      } else {
        setError(err.response?.data?.message || 'An error occurred.');
      }
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    const combinedFiles = [...images, ...fileList];
    setImages(combinedFiles);

    const urls = fileList.map(file => URL.createObjectURL(file));
    setThumbnailUrl(prevUrls => [...prevUrls, ...urls]);
  };

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedThumbnails = [...thumbnailUrl];
    updatedThumbnails.splice(index, 1);
    setThumbnailUrl(updatedThumbnails);
  };

  const handleAddItem = (setter) => {
    setter(prevItems => [...prevItems, '']);
  };

  const handleRemoveItem = (setter, index) => {
    setter(prevItems => prevItems.filter((_, i) => i !== index));
  };

  const handleItemChange = (setter, index, value) => {
    setter(prevItems => prevItems.map((item, i) => (i === index ? value : item)));
  };

  return (
    <section className="create-post">
      <div className="container">
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-post__form" onSubmit={editNote}>
          <input
            type="text"
            placeholder={t('title')}
            value={title}
            onChange={e => setTitle(e.target.value)}
            autoFocus
          />
          <input
            type="text"
            placeholder={t('branch')}
            value={concernedBranch}
            onChange={e => setConcernedBranch(e.target.value)}
          />
          <input
            type="text"
            placeholder={t('service_charges')}
            value={serviceCharges}
            onChange={e => setServiceCharges(e.target.value)}
          />

          <DynamicFieldList
            label={t('required_documents')}
            items={requiredDocuments}
            onAdd={() => handleAddItem(setRequiredDocuments)}
            onRemove={(index) => handleRemoveItem(setRequiredDocuments, index)}
            onItemChange={(index, value) => handleItemChange(setRequiredDocuments, index, value)}
            t={t}
          />

          <DynamicFieldList
            label={t('supporting_documents')}
            items={supportingDocuments}
            onAdd={() => handleAddItem(setSupportingDocuments)}
            onRemove={(index) => handleRemoveItem(setSupportingDocuments, index)}
            onItemChange={(index, value) => handleItemChange(setSupportingDocuments, index, value)}
            t={t}
          />

          <DynamicFieldList
            label={t('procedures')}
            items={procedureForProviding}
            onAdd={() => handleAddItem(setProcedureForProviding)}
            onRemove={(index) => handleRemoveItem(setProcedureForProviding, index)}
            onItemChange={(index, value) => handleItemChange(setProcedureForProviding, index, value)}
            t={t}
          />

          <DynamicFieldList
            label={t('time_taken')}
            items={timeTaken}
            onAdd={() => handleAddItem(setTimeTaken)}
            onRemove={(index) => handleRemoveItem(setTimeTaken, index)}
            onItemChange={(index, value) => handleItemChange(setTimeTaken, index, value)}
            t={t}
          />

          <DynamicFieldList
            label={t('responsible_officer')}
            items={responsibleOfficer}
            onAdd={() => handleAddItem(setResponsibleOfficer)}
            onRemove={(index) => handleRemoveItem(setResponsibleOfficer, index)}
            onItemChange={(index, value) => handleItemChange(setResponsibleOfficer, index, value)}
            t={t}
          />

          <div className="thumbnaildash2">
            <span>{t('choose_imagesfiles')}</span>
            <input type="file" onChange={handleImageChange} accept="image/*" multiple className="custom-file-input" />
            <div className="thumbnail-container">
              {images.map((image, index) => (
                <div key={index} className="thumbnail-wrapper">
                  {typeof image === 'string' ? (
                    <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} alt={image} className="thumbnail" />
                  ) : (
                    <img src={URL.createObjectURL(image)} alt="" className="thumbnail" />
                  )}
                  <button type="button" onClick={() => removeImage(index)} className="remove-button">X</button>
                </div>
              ))}
            </div>
          </div>
          <div className="boxdash">
            <button type="submit" className="btn primary submitbtn2">Update Note</button>
          </div>
        </form>
      </div>
    </section>
  );
};

const DynamicFieldList = ({ label, items, onAdd, onRemove, onItemChange, t }) => (
  <div className="dynamic-field-list">
    <label>{label}:</label>
    {items.map((item, index) => (
      <div key={index} className="item-container">
        <input
          type="text"
          value={item}
          onChange={(e) => onItemChange(index, e.target.value)}
          placeholder={`${t('enter')} ${label.toLowerCase()}`}
        />
        <button type="button" className="btn remove-btn" onClick={() => onRemove(index)}>X</button>
      </div>
    ))}
    <button type="button" className="btn add-btn" onClick={onAdd}>+ {label}</button>
  </div>
);

export default EditNote;
