import React, { useContext, useEffect } from 'react';
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentUser(null);
    navigate('/login');
  }, []); // Empty dependency array ensures this effect runs only once after component mounts

  // Render an empty fragment since no UI is needed for logout
  return <></>;
};

export default Logout;
