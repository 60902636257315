import 'react-quill/dist/quill.snow.css'
import { UserContext } from '../../context/userContext'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react'
import ReactQuill from 'react-quill';


const CreateStat = () => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])
  const [error, setError] = useState('')
  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;
  Quill.register('modules/imageResize', ImageResize);


  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [])
  
  //  for react quill
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
   }
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]




  const createStat = async (e) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('title', title)
    postData.set('description', description)
    for (let i = 0; i < images.length; i++) {
      postData.append('images', images[i]);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/stats`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 201) {
        return navigate('/create')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'images'") {
        setError("Please choose images.")
      } else {
        setError(err.response.data.message);
      }
    }
  }
  
  // to handle array of images
  const handleImageChange = (event) => {
    const files = event.target.files;
    // Convert the FileList object to an array
    const fileList = Array.from(files);
    // Update the images state with the array of selected files
    setImages(fileList);
  };

  return (
    <section className="create-post">
      <div className="container">
        {/* <h3>Create Post</h3> */}
        {error && <p className="form__error-message">
          {error}
        </p>}
        <form className="form create-post__form" onSubmit={createStat}>
          <input type='text' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
         
          {/* <div class="container-quill"> */}
          <div className="thumbnaildash2">
          <span>Choose Images or Files : </span>
              <input type="file" onChange={handleImageChange} multiple/>
          </div>
          <div style={{ height: '40vh' }}>
          <ReactQuill modules={modules} formats={formats} value={description} onChange={setDescription} style={{ height: '100%' }} /> {/* Set height of ReactQuill */}
          </div>
            <div className="boxdash">
              <button type="submit" className='btn primary submitbtn'>Publish Stats</button>
            </div>
          {/* </div> */}
            </form>
      </div>
    </section>
  )
}

export default CreateStat;

