import React, { useState, useEffect, useContext } from 'react';
import { createGraph, updateGraph, getProfiles, createProfile } from '../api/graphs';
import { UserContext } from '../../context/userContext';
import { FaPlus, FaTrash } from 'react-icons/fa';
import '../../styles/GraphForm.css'; // Assuming you create a separate CSS file for styling

const GraphForm = ({ refreshGraphs, editGraph, closeModal }) => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('pie');
  const [profile, setProfile] = useState('');
  const [newProfile, setNewProfile] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [data, setData] = useState([{ label: '', value: '' }]);
  const [seriesLabels, setSeriesLabels] = useState([{ label: '' }]); // To store series labels
  const [groupedData, setGroupedData] = useState([{ category: '', series: [] }]);
  const [error, setError] = useState('');

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await getProfiles();
        setProfiles(response.data);
      } catch (error) {
        console.error('Failed to fetch profiles', error);
        setError('Failed to fetch profiles');
      }
    };
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (editGraph) {
      setTitle(editGraph.title);
      setType(editGraph.type);
      setProfile(editGraph.profile._id || editGraph.profile);
      if (editGraph.type === 'groupedBar' || editGraph.type === 'line') {
        setGroupedData(editGraph.data.map(group => ({
          category: group.category,
          series: Object.keys(group)
            .filter(key => key !== 'category')
            .map(label => ({ label, value: group[label] }))
        })));
        setSeriesLabels(
          editGraph.data[0]
            ? Object.keys(editGraph.data[0]).filter(key => key !== 'category').map(label => ({ label }))
            : [{ label: '' }]
        );
      } else {
        setData(editGraph.data);
      }
    } else {
      resetForm();
    }
  }, [editGraph]);

  const resetForm = () => {
    setTitle('');
    setType('pie');
    setProfile('');
    setData([{ label: '', value: '' }]);
    setSeriesLabels([{ label: '' }]);
    setGroupedData([{ category: '', series: [{ label: '', value: '' }] }]);
  };

  const handleDataChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const handleGroupedDataChange = (categoryIndex, seriesIndex, field, value) => {
    const newGroupedData = [...groupedData];
    newGroupedData[categoryIndex].series[seriesIndex][field] = value;
    setGroupedData(newGroupedData);
  };

  const handleCategoryChange = (index, field, value) => {
    const newGroupedData = [...groupedData];
    newGroupedData[index][field] = value;
    setGroupedData(newGroupedData);
  };

  const handleSeriesLabelChange = (index, value) => {
    const newSeriesLabels = [...seriesLabels];
    newSeriesLabels[index].label = value;
    setSeriesLabels(newSeriesLabels);
    const newGroupedData = groupedData.map(group => ({
      ...group,
      series: newSeriesLabels.map((label, i) => ({
        label: label.label,
        value: group.series[i] ? group.series[i].value : ''
      }))
    }));
    setGroupedData(newGroupedData);
  };

  const addDataField = () => {
    setData([...data, { label: '', value: '' }]);
  };

  const removeDataField = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const addGroupedDataField = (categoryIndex) => {
    const newGroupedData = [...groupedData];
    newGroupedData[categoryIndex].series.push({ label: '', value: '' });
    setGroupedData(newGroupedData);
  };

  const addCategoryField = () => {
    setGroupedData([...groupedData, { category: '', series: seriesLabels.map(label => ({ label: label.label, value: '' })) }]);
  };

  const removeCategoryField = (index) => {
    const newGroupedData = [...groupedData];
    newGroupedData.splice(index, 1);
    setGroupedData(newGroupedData);
  };

  const addSeriesLabelField = () => {
    setSeriesLabels([...seriesLabels, { label: '' }]);
    const newGroupedData = groupedData.map(group => ({
      ...group,
      series: [...group.series, { label: '', value: '' }]
    }));
    setGroupedData(newGroupedData);
  };

  const removeSeriesLabelField = (index) => {
    const newSeriesLabels = [...seriesLabels];
    newSeriesLabels.splice(index, 1);
    setSeriesLabels(newSeriesLabels);
    const newGroupedData = groupedData.map(group => ({
      ...group,
      series: group.series.filter((_, i) => i !== index)
    }));
    setGroupedData(newGroupedData);
  };

  const handleAddProfile = async () => {
    if (newProfile) {
      try {
        const response = await createProfile({ name: newProfile }, token);
        setProfiles([...profiles, response.data]);
        setProfile(response.data._id);
        setNewProfile('');
      } catch (error) {
        console.error('Failed to add profile', error);
        setError('Failed to add profile');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formattedData;
    if (type === 'groupedBar' || type === 'line') {
      formattedData = groupedData.map(group => {
        const formattedGroup = { category: group.category };
        group.series.forEach(serie => {
          formattedGroup[serie.label] = Number(serie.value);
        });
        return formattedGroup;
      });
    } else {
      formattedData = data.map(d => ({
        label: d.label,
        value: Number(d.value)
      }));
    }

    console.log('Submitting data:', formattedData);

    try {
      if (editGraph) {
        await updateGraph(editGraph._id, { title, type, profile, data: formattedData }, token);
      } else {
        await createGraph({ title, type, profile, data: formattedData }, token);
      }
      refreshGraphs();
      closeModal();
    } catch (error) {
      console.error('Failed to create or update graph', error);
      setError('Failed to create or update graph');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form-graph create-post__form form">
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <h3>{editGraph ? 'Edit Graph' : 'Create Graph'}</h3>
      <div className="form-row">
        <select value={profile} onChange={(e) => setProfile(e.target.value)} required >
          <option value="">Select a profile</option>
          {profiles.map((prof) => (
            <option key={prof._id} value={prof._id}>{prof.name}</option>
          ))}
        </select>
        <input type="text" value={newProfile} onChange={(e) => setNewProfile(e.target.value)} placeholder="Add New Profile" />
        <button type="button" className="icon-button" onClick={handleAddProfile}>
          <FaPlus />
        </button>
      </div>
      <div>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
      </div>
      <div className="form-box">
        <label>Choose Graph Type</label>
        <select value={type} onChange={(e) => setType(e.target.value)}>
          <option value="raw">Label Value</option>
          <option value="pie">Pie Chart</option>
          <option value="bar">Bar Chart</option>
          <option value="groupedBar">Grouped Bar Chart</option>
          <option value="line">Line Chart</option>
        </select>
      </div>
      {type === 'groupedBar' || type === 'line' ? (
        <div className="series-labels">
          <h4>Series Labels</h4>
          {seriesLabels.map((seriesLabel, index) => (
            <div key={index} className="form-row">
              <input
                type="text"
                value={seriesLabel.label}
                onChange={(e) => handleSeriesLabelChange(index, e.target.value)}
                placeholder={`Series ${index + 1} Label`}
                required
              />
              <button type="button" className="icon-buttondel" onClick={() => removeSeriesLabelField(index)}>
                <FaTrash />
              </button>
            </div>
          ))}
          <button type="button" className="icon-button" onClick={addSeriesLabelField}>
            <FaPlus /> Add Series Label
          </button>
        </div>
      ) : null}
      {type === 'groupedBar' || type === 'line' ? (
        groupedData.map((group, categoryIndex) => (
          <div key={categoryIndex}>
            <input type="text" value={group.category} onChange={(e) => handleCategoryChange(categoryIndex, 'category', e.target.value)} placeholder="Category" required className='formCategory'/>
            {group.series.map((serie, seriesIndex) => (
              <div key={seriesIndex} className="form-row">
                <input type="number" value={serie.value} onChange={(e) => handleGroupedDataChange(categoryIndex, seriesIndex, 'value', e.target.value)} placeholder={serie.label} required />
                <button type="button" className="icon-buttondel" onClick={() => {
                  const newGroupedData = [...groupedData];
                  newGroupedData[categoryIndex].series.splice(seriesIndex, 1);
                  setGroupedData(newGroupedData);
                }}>
                  <FaTrash />
                </button>
              </div>
            ))}
            <button type="button" className="icon-buttondel" onClick={() => removeCategoryField(categoryIndex)}>
              <FaTrash /> Remove Category
            </button>
          </div>
        ))
      ) : (
        data.map((d, index) => (
          <div key={index} className="form-row">
            <input type="text" value={d.label} onChange={(e) => handleDataChange(index, 'label', e.target.value)} placeholder="Label" required />
            <input type="number" value={d.value} onChange={(e) => handleDataChange(index, 'value', e.target.value)} placeholder="Value" required />
            <button type="button" className="icon-buttondel" onClick={() => removeDataField(index)}>
              <FaTrash />
            </button>
          </div>
        ))
      )}
      {type === 'groupedBar' || type === 'line' ? (
        <button type="button" className="icon-button" onClick={addCategoryField}>
          <FaPlus /> Add Category
        </button>
      ) : (
        <button type="button" className="icon-button" onClick={addDataField}>
          <FaPlus /> Add Data Field
        </button>
      )}
      <button type="submit" className="submit-button">{editGraph ? 'Update Graph' : 'Create Graph'}</button>
    </form>
  );
};

export default GraphForm;
