import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import axios from 'axios'
import { FaPlus } from 'react-icons/fa';
import Loader from '../../components/Loader'



const EditContact = () => {
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [number1, setNumber1] = useState('')
    const [number2, setNumber2] = useState('')
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);


  const [error, setError] = useState('')


  const navigate = useNavigate();
  const { id } = useParams();

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  // for categories
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');

  //  redirect to login page for any user who isn't logged in
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
    fetchData();
  }, [])
  
  const fetchData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/categories`)
      .then(response => {
        setCategories(response.data.map(category => category.name));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleAddCategory = () => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/categories`, { name: newCategory })
      .then(response => {
        // Extract the new category name from the response data
        const newCategoryName = response.data.name;
        
        // Add the new category to the list
        setCategories([...categories, newCategoryName]);
  
        // Reset the input field
        setNewCategory('');
      })
      .catch(error => {
        console.error('Error adding category:', error);
      });
  };
  


  // to fetch post data

  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/contacts/${id}`)
        setName(response.data.name)
        setAddress(response.data.address)
        setNumber1(response.data.number1)
        setNumber2(response.data.number2)
        setDescription(response.data.description)
        setCategory(response.data.category)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false);
    }
    getPost();
  }, [])

  if (isLoading) {
    return <Loader/>
  }


  // to edit post
  const editProduct = async (e ) => {
    e.preventDefault();

    const postData = new FormData();
    postData.set('name', name)
    postData.set('address', address)
    postData.set('number1', number1)
    postData.set('number2', number2)
    postData.set('description', description)
    postData.set('category', category)

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/contacts/${id}`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } })
      if (response.status == 200) {
        return navigate('/myposts')
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a thumbnail.")
      } else {
        setError(err.response.data.message);
      }
    }
  }


  return (
    <section className="create-post">
      <div className="container">
        <h2>Edit Contact</h2>
        {error && <p className="form__error-message">
          {error}
        </p>}
        {/* to add new category */}
        <div className="category-container">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Enter new category"
              className="half-width-input"
            />
          <button onClick={handleAddCategory} className="add-button">
            <FaPlus />
          </button>
        </div>
        {/* ---------------eof---------------- */}
        <form className="form create-post__form" onSubmit={editProduct}>
        <div className="box"> <select name='category' value={category} onChange={e => setCategory(e.target.value)}>
            {
              categories.map(cat => <option key={cat}>{cat}</option>)
            }
            </select>
            </div>
            <input type='text' placeholder='Name' value={name} onChange={e => setName(e.target.value)} autoFocus />
            <input type='text' placeholder='Address' value={address} onChange={e => setAddress(e.target.value)} autoFocus />
            <input type='text' placeholder='Primary number' value={number1} onChange={e => setNumber1(e.target.value)} autoFocus />
            <input type='text' placeholder='Secondary number2' value={number2} onChange={e => setNumber2(e.target.value)} autoFocus />
            <input type='text' placeholder='Description' value={description} onChange={e => setDescription(e.target.value)} autoFocus />
          <button type="submit" className='btn primary'>Update Contact</button>
        </form>
      </div>
    </section>
  )
}

export default EditContact
