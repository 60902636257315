import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/userContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import Loader from '../../components/Loader'


const CreateContact = () => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [number1, setNumber1] = useState('');
  const [number2, setNumber2] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  // for categories
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/categories`)
      .then(response => {
        setCategories(response.data.map(category => category.name));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  
  const handleAddCategory = () => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/categories`, { name: newCategory })
      .then(response => {
        // Extract the new category name from the response data
        const newCategoryName = response.data.name;
        
        // Add the new category to the list
        setCategories([...categories, newCategoryName]);
  
        // Reset the input field
        setNewCategory('');
      })
      .catch(error => {
        console.error('Error adding category:', error);
      });
  };
  

  const createContact = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const postData = new FormData();
    postData.set('name', name);
    postData.set('address', address);
    postData.set('number1', number1);
    postData.set('number2', number2);
    postData.set('description', description);
    postData.set('category', category);


    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/contacts`, postData, { withCredentials: true, headers: { Authorization: `Bearer ${token}` } });
      if (response.status === 201) {
        return navigate('/create');
      }
    } catch (err) {
      if (err.response.data.message === "TypeError: Cannot read properties of null (reading 'thumbnail'") {
        setError("Please choose a thumbnail.");
      } else {
        setError(err.response.data.message);
      }
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader/>
  }

  return (
    <section className="create-post">
      <div className="container">
        {error && <p className="form__error-message">{error}</p>}

        {/* Render categories and input field for adding new category */}
        {/* <div> */}
          {/* <h1>Categories</h1>
          <ul>
            {categories.map((category, index) => (
              <li key={index}>{category}</li>
            ))}
          </ul> */}
          <div className="category-container">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Enter new category"
              className="half-width-input"
            />
          <button onClick={handleAddCategory} className="add-button">
            <FaPlus />
          </button>
        </div>
        {/* </div> */}

        <form className="form create-post__form" onSubmit={createContact}>
        <div className="box"> <select name='category' value={category} onChange={e => setCategory(e.target.value)}>
            {
              categories.map(cat => <option key={cat}>{cat}</option>)
            }
            </select>
            </div>
          <input type='text' placeholder='Name' value={name} onChange={e => setName(e.target.value)} autoFocus />
          <input type='text' placeholder='Primary Number' value={number1} onChange={e => setNumber1(e.target.value)} autoFocus />
          <input type='text' placeholder='Secondary Number' value={number2} onChange={e => setNumber2(e.target.value)} autoFocus />
          <input type='text' placeholder='Address' value={address} onChange={e => setAddress(e.target.value)} autoFocus />
          <input type='text' placeholder='Description' value={description} onChange={e => setDescription(e.target.value)} autoFocus />
          <div className="boxdash">
            <button type="submit" className='btn primary submitbtn'>Publish Contact</button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateContact;
