import React from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LineChart, Line, ResponsiveContainer } from 'recharts';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const Graph = ({ graph }) => {
 if (graph.type === 'pie') {
   return (
     <ResponsiveContainer width="100%" height={400} className="dash-item">
       <h5>{graph.title}</h5>
       <PieChart>
         <Pie data={graph.data} dataKey="value" nameKey="label" cx="50%" cy="50%" outerRadius={150} label>
           {graph.data.map((entry, index) => (
             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
           ))}
         </Pie>
         <Tooltip />
       </PieChart>
     </ResponsiveContainer>
   );
 }


 if (graph.type === 'bar') {
   return (
     <ResponsiveContainer width="100%" height={400} className="dash-item">
       <h5>{graph.title}</h5>
       <BarChart data={graph.data}>
         <XAxis dataKey="label" />
         <YAxis />
         <Tooltip />
         <Legend />
         <Bar dataKey="value" fill="#8884d8" />
       </BarChart>
     </ResponsiveContainer>
   );
 }


 if (graph.type === 'groupedBar') {
   return (
     <ResponsiveContainer width="100%" height={400} className="dash-item">
       <h5>{graph.title}</h5>
       <BarChart data={graph.data}>
         <XAxis dataKey="category" />
         <YAxis />
         <Tooltip />
         <Legend />
         {Object.keys(graph.data[0]).filter(key => key !== 'category').map((key, index) => (
           <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]} />
         ))}
       </BarChart>
     </ResponsiveContainer>
   );
 }


 if (graph.type === 'line') {
   return (
     <ResponsiveContainer width="100%" height={400} className="dash-item">
       <h5>{graph.title}</h5>
       <LineChart data={graph.data}>
         <XAxis dataKey="category" />
         <YAxis />
         <Tooltip />
         <Legend />
         {Object.keys(graph.data[0]).filter(key => key !== 'category').map((key, index) => (
           <Line key={key} type="monotone" dataKey={key} stroke={COLORS[index % COLORS.length]} />
         ))}
       </LineChart>
     </ResponsiveContainer>
   );
 }


 if (graph.type === 'raw') {
   return (
     <div className="dash-item">
       <h5>{graph.title}</h5>
       {graph.data.map((entry, index) => (
         <div key={index} className="raw-item">
           <strong>{entry.label}:</strong> {entry.value}
         </div>
       ))}
     </div>
   );
 }


 return null;
};


export default Graph;



